<script setup>
import ButtonSecondary from "@/components/buttons/ButtonSecondary.vue";
import { useRouter } from "vue-router";

defineProps({
  disabled: {
    type: Boolean,
    default: false,
  },
});

const router = useRouter();

function goBack() {
  if (window.history.state.back === null) {
    router.push({ name: "home" }); //or whichever path you required
  } else {
    router.back();
  }
}
</script>

<template>
  <div>
    <button-secondary @click.prevent="goBack" :disabled="disabled">
      <slot>Volver</slot>
    </button-secondary>
  </div>
</template>
