<template>
  <div>
    <svg
      id="Grupo_241"
      data-name="Grupo 241"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="25.335"
      height="22.828"
      viewBox="0 0 25.335 22.828"
    >
      <defs>
        <clipPath id="clip-path">
          <rect id="Rectángulo_316" data-name="Rectángulo 316" width="25.335" height="22.828" fill="#bbc1c5" />
        </clipPath>
      </defs>
      <g id="Grupo_240" data-name="Grupo 240" clip-path="url(#clip-path)">
        <path
          id="Trazado_7512"
          data-name="Trazado 7512"
          d="M157.738,19.662a.742.742,0,0,1-.524-.217l-6.329-6.329a.742.742,0,0,1,.032-1.079L163.672.7a2.767,2.767,0,0,1,3.872.191L169.68,3.2a2.767,2.767,0,0,1,.009,3.744l-11.4,12.472a.742.742,0,0,1-.531.241h-.017m-5.249-7.039,5.225,5.225,10.88-11.9a1.276,1.276,0,0,0,0-1.737L166.454,1.9a1.29,1.29,0,0,0-1.8-.089Z"
          transform="translate(-145.08 0)"
          fill="#bbc1c5"
        />
        <path
          id="Trazado_7513"
          data-name="Trazado 7513"
          d="M5.539,484.568H.742a.742.742,0,0,1-.521-1.27l3.61-3.56a.742.742,0,0,1,1.045,0l2.374,2.374a.742.742,0,0,1,0,1.049l-1.187,1.187a.742.742,0,0,1-.524.217M2.55,483.084H5.231l.445-.445-1.328-1.328Z"
          transform="translate(0 -461.74)"
          fill="#bbc1c5"
        />
        <path
          id="Trazado_7514"
          data-name="Trazado 7514"
          d="M100.4,329.315a.742.742,0,0,1-.524-.217c-1.237-1.237-1.83-1.828-2.115-2.111l-.222-.221a.764.764,0,0,1-.242-.7.743.743,0,0,1,.214-.4,2.233,2.233,0,0,0,.575-2.1,3.557,3.557,0,0,1,.931-3.361l.463-.462a.742.742,0,0,1,1.049,0l6.329,6.329a.742.742,0,0,1,0,1.049l-.462.462a3.557,3.557,0,0,1-3.361.931,2.234,2.234,0,0,0-2.111.585.742.742,0,0,1-.524.217m-1.379-3.167,1.433,1.43a3.727,3.727,0,0,1,2.941-.5,2.067,2.067,0,0,0,1.889-.48l-5.278-5.278a2.067,2.067,0,0,0-.48,1.889,3.728,3.728,0,0,1-.505,2.943"
          transform="translate(-93.676 -307.674)"
          fill="#bbc1c5"
        />
        <path
          id="Trazado_7515"
          data-name="Trazado 7515"
          d="M350.529,577.007h-7.121a.742.742,0,1,1,0-1.483h7.121a.742.742,0,1,1,0,1.483"
          transform="translate(-329.959 -554.18)"
          fill="#bbc1c5"
        />
      </g>
    </svg>
  </div>
</template>
