<script setup>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import ButtonTertiary from "@/components/buttons/ButtonTertiary.vue";
import { ref } from "vue";
import BaseTextarea from "@/components/forms/BaseTextarea.vue";
import BaseLabel from "@/components/forms/BaseLabel.vue";

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  question: {
    type: [Object, null],
    required: true,
  },
});

const feedback = ref("");
const emit = defineEmits(["close-dialog", "accept"]);

function sendError() {
  emit("accept", {
    question: props.question.id,
    feedback: feedback.value,
  });
}
</script>

<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="$emit('close-dialog')" class="relative z-50">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-secondary-100/90 backdrop-blur-sm" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                <slot name="title">Informar pregunta errónea</slot>
              </DialogTitle>
              <p>¿Desea informar de que la siguiente pregunta es errónea:</p>
              <p class="mx-4 my-5">
                <i v-if="question != null">{{ question.statement }}</i>
              </p>
              <dl>
                <div class="">
                  <base-label label="Notas" for-label="feedback" />
                  <base-textarea v-model="feedback" name="feedback"></base-textarea>
                </div>
              </dl>
              <div class="mt-4">
                <button-tertiary class="mr-2" @click.prevent="sendError">
                  <slot name="buttonText">Sí</slot>
                </button-tertiary>
                <button-tertiary @click.prevent="$emit('close-dialog')">
                  <slot name="buttonText">No</slot>
                </button-tertiary>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
