export const NO_PUBLICADO = "not_published";
export const PUBLICADO = "published";
export const DESPUBLICADO = "despublished";

export function publishedStateFormatter(value) {
  if (value === PUBLICADO) {
    return "Publicado";
  } else if (value === NO_PUBLICADO) {
    return "No publicado";
  } else if (value === DESPUBLICADO) {
    return "Despublicado";
  }
  return "-";
}
