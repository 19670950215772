import { computed, ref, watch } from "vue";
import { useWS } from "@/components/composables/ws-call.js";

export function usePagination(
  webserviceCall,
  pageSizeParam = 10,
  offsetParam = 0,
  skipInitialRefreshParam = false,
  showLoadingAtStart = false
) {
  const { callWs, record, isLoading, error } = useWS(webserviceCall, showLoadingAtStart);

  const skipInitialRefresh = ref(skipInitialRefreshParam);
  const pageSize = ref(pageSizeParam);
  const offset = ref(offsetParam);
  const cachedParams = ref({});

  const items = computed(() => {
    if (record.value != null) {
      return record.value.results;
    }
    return [];
  });

  const totalResults = computed(() => {
    if (record.value != null) {
      return record.value.count;
    }
    return 0;
  });

  const page = computed(() => {
    if (record.value != null) {
      return offset.value / pageSize.value + 1;
    }
    return 1;
  });

  const getList = async function (params) {
    cachedParams.value = params;
    const wsParams = {
      ...params,
      limit: pageSize.value,
      offset: offset.value,
    };
    await callWs(wsParams);
    if (skipInitialRefresh.value) {
      skipInitialRefresh.value = false;
    }
  };

  const refreshList = async function () {
    await getList(cachedParams.value);
  };

  watch(pageSize, async (newPageSize, oldPageSize) => {
    if (!skipInitialRefresh.value) {
      offset.value = 0;
      await refreshList();
    }
  });

  watch(totalResults, async (newTotalResults, oldTotalResults) => {
    if (newTotalResults < offset.value) {
      offset.value = 0;
      await refreshList();
    }
  });

  return { record, items, totalResults, page, pageSize, offset, isLoading, error, getList, refreshList };
}
