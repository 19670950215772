<script setup>
import { computed } from "vue";
import Match from "lodash";

const props = defineProps({
  title: {
    type: String,
    required: true,
  },
  correctAnswers: {
    type: Number,
    default: 0,
  },
  incorrectAnswers: {
    type: Number,
    default: 0,
  },
  totalAnswers: {
    type: Number,
    default: 0,
  },
});

const blankAnswers = computed(() => {
  return props.totalAnswers - (props.correctAnswers + props.incorrectAnswers);
});

const percentageCorrect = computed(() => {
  return Match.round((props.correctAnswers / props.totalAnswers) * 100);
});

const percentageError = computed(() => {
  return Match.round((props.incorrectAnswers / props.totalAnswers) * 100);
});

const percentageBlank = computed(() => {
  return Match.round((blankAnswers.value / props.totalAnswers) * 100);
});
</script>

<template>
  <div class="mb-4 flex items-center rounded-lg bg-background px-5 py-4">
    <div class="flex w-full flex-col">
      <div class="mb-3 flex flex-row justify-between">
        <p class="text-lg font-semibold text-secondary-800">
          {{ title }}
        </p>
        <slot name="actions"></slot>
      </div>
      <slot name="description"></slot>
      <div class="relative flex items-center">
        <div class="mb-2 flex h-1.5 w-full flex-row rounded-full bg-gray-400">
          <div
            class="h-1.5 rounded bg-green-solid"
            data-tip="{{ percentageCorrect }}%"
            :style="{ width: percentageCorrect + '%' }"
          />
          <div
            class="tooltip h-1.5 rounded bg-red-600"
            data-tip="{{ percentageError }}%"
            :style="{ width: percentageError + '%' }"
          />
        </div>
      </div>

      <div class="mt-2 flex w-full justify-between">
        <p class="flex gap-1 text-secondary-400">
          <span class="text-green-solid">{{ percentageCorrect }}%</span>
          <span>|</span>
          <span class="text-red-600">{{ percentageError }}%</span>
          <span>|</span>
          <span class="text-secondary-400">{{ percentageBlank }}%</span>
        </p>
        <p class="flex gap-1 text-secondary-400">
          <span class="mr-3 text-secondary-800">Respuestas:</span>
          <span class="text-green-solid">{{ correctAnswers }}</span>
          <span>|</span>
          <span class="text-red-600">{{ incorrectAnswers }}</span>
          <span>|</span>
          <span class="text-secondary-400">{{ blankAnswers }}</span>
        </p>
      </div>
    </div>
  </div>
</template>
