<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="25.334"
      height="22.827"
      viewBox="0 0 25.334 22.827"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectángulo_387"
            data-name="Rectángulo 387"
            width="25.334"
            height="22.827"
            transform="translate(0 0)"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Grupo_296" data-name="Grupo 296" transform="translate(0 0)">
        <g id="Grupo_295" data-name="Grupo 295" clip-path="url(#clip-path)">
          <path
            id="Trazado_7548"
            data-name="Trazado 7548"
            d="M20.57,22.826H13.449a.742.742,0,0,1-.055-1.483.5.5,0,0,1,.055,0H20.57a.742.742,0,1,1,.055,1.483H20.57"
            fill="#c4d600"
          />
          <path
            id="Trazado_7549"
            data-name="Trazado 7549"
            d="M24.6,3.2,22.464.89A2.767,2.767,0,0,0,18.592.7L5.837,12.036l-.031.03a.741.741,0,0,0,0,1.049l6.329,6.329a.742.742,0,0,0,.524.217l0,0h.017a.736.736,0,0,0,.5-.217l.007.007.024-.029,0,0,11.4-12.472A2.767,2.767,0,0,0,24.6,3.2"
            fill="#c4d600"
          />
          <path
            id="Trazado_7550"
            data-name="Trazado 7550"
            d="M7.25,20.371,4.876,18a.742.742,0,0,0-1.045,0l-3.61,3.56a.742.742,0,0,0,.521,1.27h4.8v0a.742.742,0,0,0,.524-.217L7.25,21.42a.741.741,0,0,0,0-1.049"
            fill="#c4d600"
          />
          <path
            id="Trazado_7551"
            data-name="Trazado 7551"
            d="M6.724,21.64a.74.74,0,0,1-.524-.217c-1.237-1.237-1.83-1.828-2.115-2.111l-.222-.221a.765.765,0,0,1-.242-.7.748.748,0,0,1,.214-.4,2.233,2.233,0,0,0,.575-2.1,3.557,3.557,0,0,1,.931-3.361l.463-.462a.741.741,0,0,1,1.049,0L13.182,18.4a.741.741,0,0,1,0,1.049l-.462.462a3.557,3.557,0,0,1-3.361.931,2.234,2.234,0,0,0-2.111.585.74.74,0,0,1-.524.217M5.345,18.474,6.778,19.9a3.727,3.727,0,0,1,2.941-.5,2.067,2.067,0,0,0,1.889-.48L6.33,13.646a2.067,2.067,0,0,0-.48,1.889,3.729,3.729,0,0,1-.505,2.943"
            fill="#c4d600"
          />
        </g>
      </g>
    </svg>
  </div>
</template>
