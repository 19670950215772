<script setup>
import "quill/dist/quill.snow.css";
import { onMounted, ref, watch } from "vue";
import Quill from "quill/dist/quill";

// Qu

const props = defineProps({
  value: {
    type: String,
    default: null,
  },
  id: {
    type: String,
    default: "id-rich-textarea",
  },
  name: {
    type: String,
    default: "rich-textarea",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
const data = ref(props.value);
const emit = defineEmits(["value-updated"]);

onMounted(async () => {
  let quill = new Quill("#" + props.id, {
    theme: "snow",
    modules: {
      toolbar: [["bold", "italic", "underline"], [{ list: "ordered" }, { list: "bullet" }], ["clean"]],
    },
  });

  quill.on("text-change", function (delta, oldDelta, source) {
    emit("value-updated", document.querySelector("#" + props.id + "  .ql-editor").innerHTML);
  });
  if (props.disabled) {
    quill.disable();
  }
});
</script>

<template>
  <div :id="id" class="quill-rich-text" v-html="data"></div>
</template>

<style scoped>
.quill-rich-text.ql-container.ql-snow {
  height: auto !important;
}
</style>
