<script setup>
import { PlusCircleIcon, TrashIcon } from "@heroicons/vue/24/outline/index.js";
import QuestionDetailMultipleSelection from "@/components/imported-questions/QuestionDetailMultipleSelection.vue";
import { computed, onMounted, ref } from "vue";
import { usePagination } from "@/components/composables/ws-pagination.js";
import MainRepository from "@/repositories/MainRepository.js";
import BaseComplexSelect from "@/components/forms/BaseComplexSelect.vue";
import { cloneDeep } from "lodash";
import BaseLoading from "@/components/BaseLoading.vue";
import { useToast } from "@/components/composables/notifications.js";

const props = defineProps({
  modelValue: {
    type: Array,
    required: true,
  },
  emptyOppositionError: {
    type: String,
    default: "Es necesario seleccionar al menos una oposición",
  },
  selectThemes: {
    type: Boolean,
    default: true,
  },
  allowDelete: {
    type: Boolean,
    default: true,
  },
  allowAdd: {
    type: Boolean,
    default: true,
  },
  allowAddMessage: {
    type: String,
    default: "",
  },
  isPublish: {
    type: Boolean,
    default: false,
  },
  isInExam: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["update:modelValue"]);

const { sendServerError } = useToast();

const { items: contentLevelOptions, getList: getContentLevels } = usePagination(
  MainRepository.contentLevelList,
  500,
  0
);
const { items: oppositions, getList: getOppositions } = usePagination(MainRepository.oppositionList, 200, 0);

const availableOppositionContentLevels = computed(() => {
  let availableValues = [];
  for (let i = 0; i < oppositionContentLevels.value.length; i++) {
    let contentLevels = oppositionContentLevels.value[i];
    let contentLevelIds = props.modelValue[i].contentLevels.map((o) => o.contentLevel.id);
    let filtered = contentLevels.filter((o) => !contentLevelIds.includes(o.id));
    availableValues.push(filtered);
  }
  return availableValues;
});

onMounted(async () => {
  try {
    // this will limit to oppositions with questions
    // since new oppositions need to be handled by administrators is not a problem to filter in this way
    // otherwise a lot of oppositions are shown
    await getOppositions({ with_questions: true });

    for (let i = 0; i < props.modelValue.length; i++) {
      let opposition = props.modelValue[i];
      await getContentLevels({
        opposition: opposition.opposition.id,
        only_children: true,
      });
      oppositionContentLevels.value.push(contentLevelOptions.value);
    }
  } catch (e) {
    sendServerError(e, "IMPORT-QST");
  }
});

const selectedOpposition = ref(null);
const availableOppositions = computed(() => {
  return oppositions.value.filter((o) => !props.modelValue.map((o) => o.opposition.id).includes(o.id));
});

const isOppositionRemovable = computed(() => {
  if (!props.allowDelete) {
    return props.modelValue.map(() => false);
  }
  return props.modelValue.map((opposition) => {
    if (!props.isInExam) {
      return true;
    }
    if (!props.isPublish) {
      return true;
    }
    return !opposition.id;
  });
});

async function addOpposition() {
  if (selectedOpposition.value != null) {
    let newOppositionsData = props.modelValue.concat([
      {
        opposition: selectedOpposition.value,
        contentLevels: [],
      },
    ]);
    emit("update:modelValue", newOppositionsData);

    try {
      await getContentLevels({
        opposition: selectedOpposition.value.id,
        only_children: true,
      });
      oppositionContentLevels.value.push(contentLevelOptions.value);

      selectedOpposition.value = null;
      showOppositionSelector.value = false;
    } catch (error) {
      sendServerError(error);
    }
  }
}

function removeOpposition(opposition, index) {
  let newOppositionsData = cloneDeep(props.modelValue).filter(
    (item) => item.opposition.id !== opposition.opposition.id
  );
  oppositionContentLevels.value.splice(index, 1);
  emit("update:modelValue", newOppositionsData);
}

function contentTitle(description) {
  if (description.parentName != null) {
    return `${description.parentName} -> ${description.name}`;
  }
  return description.name;
}

const oppositionContentLevels = ref([]);
const showOppositionSelector = ref(false);
const showContentLevelSelect = ref(false);

function addContentLevel(contentLevel, opposition, oppositionIndex) {
  let newOppositionsData = cloneDeep(props.modelValue);
  newOppositionsData[oppositionIndex].contentLevels.push({ contentLevel: contentLevel });
  emit("update:modelValue", newOppositionsData);

  contentLevel.value = null;
  showContentLevelSelect.value = false;
}

function removeContentLevel(contentLevel, opposition, oppositionIndex) {
  let newOppositionsData = cloneDeep(props.modelValue);
  newOppositionsData[oppositionIndex].contentLevels = props.modelValue[oppositionIndex].contentLevels.filter(
    (c) => c.contentLevel.id !== contentLevel.id
  );
  emit("update:modelValue", newOppositionsData);
}
</script>

<template>
  <div>
    <div class="mb-2">
      <div
        v-for="(opposition, index) in modelValue"
        :key="opposition.id"
        :class="[
          'col-span-3 mb-3 border-2 border-secondary-200 px-4 py-3 sm:grid sm:grid-cols-3 sm:px-6',
          isOppositionRemovable[index] || !allowDelete ? '' : 'bg-gray-200',
        ]"
      >
        <dt class="text-sm font-medium text-secondary-600">Oposición</dt>
        <dd class="my-2 mt-1 text-sm text-secondary-800 sm:mt-0">
          {{ opposition.opposition.name }}
        </dd>
        <button
          v-if="isOppositionRemovable[index]"
          class="justify-self-end p-2 hover:bg-primary-200 sm:relative sm:bottom-1.5 sm:left-4"
          @click.prevent="removeOpposition(opposition, index)"
          title="Eliminar pregunta de la oposición"
        >
          <trash-icon class="block h-5 w-5" />
        </button>

        <question-detail-multiple-selection
          v-if="selectThemes && modelValue.length === availableOppositionContentLevels.length"
          class="col-span-3 pr-3"
          label="Temas"
          :values="opposition.contentLevels.map((o) => o.contentLevel)"
          :value-label="(o) => contentTitle(o)"
          :select-values="availableOppositionContentLevels[index]"
          :select-value-label="(o) => contentTitle(o)"
          :select-value-value="(o) => o.id"
          bg-color-class="border-secondary-50"
          @remove="removeContentLevel($event, opposition, index)"
          @change="addContentLevel($event, opposition, index)"
        />
        <base-loading v-else-if="selectThemes && modelValue.length !== availableOppositionContentLevels.length" />
      </div>
    </div>
    <button
      v-if="allowAdd && showOppositionSelector === false"
      class="p-2 hover:bg-primary-200"
      title="Añadir nueva oposición"
      @click.prevent="showOppositionSelector = true"
    >
      <plus-circle-icon class="block h-5 w-5" />
    </button>
    <base-complex-select
      id="select-opposition"
      v-if="allowAdd && showOppositionSelector"
      name="select-opposition"
      v-model="selectedOpposition"
      :value-option="(o) => o.id"
      :label-option="(o) => o.name"
      :options="availableOppositions"
      show-blank-option
      @change="addOpposition"
    />
    <div v-if="modelValue.length <= 0" class="col-span-3 text-red-200">
      {{ emptyOppositionError }}
    </div>
    <p v-if="!allowAdd">{{ allowAddMessage }}</p>
  </div>
</template>

<style scoped></style>
