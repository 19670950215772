<script setup>
import { ref } from "vue";

const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
});
const emit = defineEmits(["change"]);

const selected = ref(0);

const select = (index) => {
  selected.value = index;
  emit("change", props.options[index].option);
};
</script>

<template>
  <div class="relative flex justify-center">
    <div class="grid grid-cols-3 grid-rows-1 justify-center rounded-full bg-background p-3">
      <p
        v-for="(option, index) in props.options"
        :key="option.option"
        class="z-10 w-28 cursor-pointer rounded py-1 text-center text-lg sm:w-36"
        @click="select(index)"
      >
        {{ option.name }}
      </p>
    </div>
    <div class="absolute grid grid-cols-3 grid-rows-1 rounded-full p-1">
      <span
        class="z-0 col-start-1 mt-1 h-11 w-28 cursor-pointer rounded-full bg-white text-center text-secondary-800 shadow-md transition-transform sm:w-36"
        :class="{
          'translate-x-0': selected === 0,
          'translate-x-28 sm:translate-x-36': selected === 1,
          'translate-x-56 sm:translate-x-72': selected === 2,
        }"
      >
      </span>
    </div>
  </div>
</template>
