<script setup>
import StaffRepository from "@/repositories/StaffRepository.js";
import { computed, onMounted, ref } from "vue";
import TableWithFilters from "@/components/tables/TableWithFilters.vue";
import { useToast } from "@/components/composables/notifications.js";

const props = defineProps({
  wopoUserId: {
    type: [Number, String],
    required: true,
  },
});
const tablePaid = ref(null);

const { sendServerError } = useToast();

const paidSubscriptionsFields = computed(() => {
  return [
    { label: "Oposición", key: "publicExamination", formatter: (item) => item.shortName },
    { label: "Estado", key: "status" },
    { label: "Expiración", key: "end" },
  ];
});

onMounted(async () => {
  try {
    await refreshDataPaid();
  } catch (e) {
    sendServerError(e, "IN-INIT");
  }
});

function buildParamsPaid() {
  return {
    customer__user__wopocampususer: props.wopoUserId,
  };
}

async function refreshDataPaid() {
  try {
    await tablePaid.value.refreshData(buildParamsPaid());
  } catch (e) {
    sendServerError(e, "USR-LIST");
  }
}
</script>
<template>
  <div>
    <table-with-filters
      ref="tablePaid"
      title="Suscripciones de pago"
      :fields="paidSubscriptionsFields"
      :call="StaffRepository.paidSubscriptionList"
      :skip-initial-refresh="true"
      class="mt-8"
    />
  </div>
</template>
