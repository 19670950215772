<script setup>
import { computed } from "vue";
import _ from "lodash";

const props = defineProps({
  page: {
    type: [String, Number],
    required: true,
  },
  pageSize: {
    type: [String, Number],
    required: true,
  },
  totalResults: {
    type: Number,
    required: true,
  },
});

const noPages = computed(() => {
  if (props.pageSize > 0) {
    return _.ceil(props.totalResults / props.pageSize);
  }
  return 1;
});

const emit = defineEmits(["page"]);

function increase() {
  if (props.page < noPages.value) {
    setPage(props.page + 1);
  }
}

function decrease() {
  if (props.page > 1) {
    setPage(props.page - 1);
  }
}

function onInput(event) {
  setPage(parseInt(event.target.value));
}

function setPage(page) {
  emit("page", page);
}
</script>

<template>
  <div class="flex items-center justify-center text-base text-secondary-800">
    <button class="text-base font-light text-secondary-800" :value="page - 1" :disabled="page === 1" @click="decrease">
      &lt;
    </button>
    <div class="mx-5 flex text-secondary-800">
      <span>Página</span>
      <input
        type="number"
        min="1"
        max="noPages"
        class="mx-2 w-14 rounded border border-table-border bg-white px-2 text-center text-base font-light text-secondary-800"
        :value="page"
        @change="onInput"
      />
      <span>de {{ noPages }}</span>
    </div>
    <button
      class="text-base font-light text-secondary-800"
      :value="page + 1"
      :disabled="page >= noPages"
      @click="increase"
    >
      &gt;
    </button>
  </div>
</template>
