import {
  ATTEMPT_CUSTOM,
  ATTEMPT_CUSTOM_BLANK,
  ATTEMPT_CUSTOM_FAVOURITES,
  ATTEMPT_CUSTOM_INCORRECT,
  ATTEMPT_CUSTOM_UNCERTAIN,
  ATTEMPT_PRACTICAL_CASE,
  ATTEMPT_PRACTICAL_CASE_PREVIOUS_CALL,
  ATTEMPT_PREVIOUS_CALL,
  ATTEMPT_BY_GENERAL_PROCESAL,
} from "@/components/utils.js";

export function attemptTypeIconSelector(type, gray = false) {
  switch (type) {
    case ATTEMPT_CUSTOM:
      if (gray) return "tpersonalizadog";
      return "tpersonalizado";
    case ATTEMPT_BY_GENERAL_PROCESAL:
      if (gray) return "preguntas_repasog";
      return "preguntas_repaso";
    case ATTEMPT_PREVIOUS_CALL:
      if (gray) return "tconvantg";
      return "tconvant";
    case ATTEMPT_CUSTOM_INCORRECT:
      return "tfalladas";
    case ATTEMPT_CUSTOM_UNCERTAIN:
      return "pdudosas";
    case ATTEMPT_CUSTOM_BLANK:
      return "tblanco";
    case ATTEMPT_CUSTOM_FAVOURITES:
      return "tfavorita";
    case ATTEMPT_PRACTICAL_CASE:
      return "tsupprac";
    case ATTEMPT_PRACTICAL_CASE_PREVIOUS_CALL:
      return "tsuppracof";
    default:
      return "";
  }
}
