<template>
  <div>
    <svg xmlns="http://www.w3.org/2000/svg" width="22.841" height="22.828" viewBox="0 0 22.841 22.828">
      <g id="help_2_" data-name="help (2)" transform="translate(-5.999 -6.036)">
        <g id="LWPOLYLINE" transform="translate(13.704 11.762)">
          <g id="Grupo_219" data-name="Grupo 219">
            <path
              id="Trazado_7503"
              data-name="Trazado 7503"
              d="M26.957,27.783h-.008a.879.879,0,0,1-.871-.887l0-.439a.724.724,0,0,1,0-.076,4.207,4.207,0,0,1,1.652-2.691c.231-.214.45-.415.636-.626a1.19,1.19,0,0,0,.21-1.414,1.941,1.941,0,0,0-2.132-.764,1.634,1.634,0,0,0-1.187,1.244.879.879,0,0,1-1.7-.455,3.332,3.332,0,0,1,2.492-2.5,3.656,3.656,0,0,1,4.066,1.634,2.934,2.934,0,0,1-.437,3.423,10.512,10.512,0,0,1-.759.751c-.63.582-1.024.971-1.091,1.533l0,.4A.878.878,0,0,1,26.957,27.783Z"
              transform="translate(-23.537 -19.069)"
              fill="#bbc1c5"
            />
          </g>
        </g>
        <g id="POINT" transform="translate(16.244 21.368)">
          <g id="Grupo_220" data-name="Grupo 220" transform="translate(0)">
            <path
              id="Trazado_7504"
              data-name="Trazado 7504"
              d="M30.2,42.677a.886.886,0,0,1-.619-.255.875.875,0,0,1,0-1.243.909.909,0,0,1,1.239,0,.871.871,0,0,1,.259.619.87.87,0,0,1-.879.879Z"
              transform="translate(-29.32 -40.935)"
              fill="#bbc1c5"
            />
          </g>
        </g>
        <g id="CIRCLE_2_" transform="translate(5.999 6.036)">
          <g id="Grupo_221" data-name="Grupo 221">
            <path
              id="Trazado_7505"
              data-name="Trazado 7505"
              d="M17.388,28.864a11.33,11.33,0,0,1-2.945-.387,11.491,11.491,0,1,1,2.945.387Zm.055-21.07A9.659,9.659,0,0,0,14.9,26.78a9.588,9.588,0,0,0,2.488.327h0A9.664,9.664,0,0,0,21.736,8.8a9.642,9.642,0,0,0-4.293-1Z"
              transform="translate(-5.999 -6.036)"
              fill="#bbc1c5"
            />
          </g>
        </g>
      </g>
    </svg>
  </div>
</template>
