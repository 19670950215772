<script setup>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import ButtonTertiary from "@/components/buttons/ButtonTertiary.vue";
import { debounce } from "lodash";

defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
  desiredQuestions: {
    type: Number,
    required: true,
  },
  availableQuestions: {
    type: Number,
    required: true,
  },
});

const emit = defineEmits(["close-dialog", "accept"]);

// since the accept function can be an api call or something with
// heavy loading
const throttledAccept = debounce(function () {
  emit("accept");
  emit("close-dialog");
}, 500);
</script>

<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="$emit('close-dialog')" class="relative z-50">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-secondary-100/90 backdrop-blur-sm" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                <slot name="title">Número de preguntas insuficientes</slot>
              </DialogTitle>
              <slot
                >El número de preguntas deseadas es {{ desiredQuestions }} pero solo hay {{ availableQuestions }}.
                ¿Desea proceder igualmente?
              </slot>
              <div class="mt-4">
                <button-tertiary class="mr-2" @click="throttledAccept">
                  <slot name="buttonText">Sí</slot>
                </button-tertiary>
                <button-tertiary @click="$emit('close-dialog')">
                  <slot name="buttonText">No</slot>
                </button-tertiary>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
