<script setup>
import BaseCard from "@/components/BaseCard.vue";
import BackButton from "@/components/buttons/BackButton.vue";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import TitleHeaderView from "@/layout-components/TitleHeaderView.vue";
import { useToast } from "@/components/composables/notifications.js";
import MainRepository from "@/repositories/MainRepository.js";
import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import QuestionDummy from "@/components/imported-questions/QuestionDummy.vue";
import QuestionPreviewBody from "@/components/imported-questions/QuestionPreviewBody.vue";

const route = useRoute();
const { sendServerError } = useToast();

const exam = ref(null);
const questions = ref(null);

onMounted(async () => {
  let examId = route.params.examId;
  try {
    let response = await MainRepository.examDetail(examId)();
    exam.value = response.data;
    await getQuestionList(examId);
  } catch (e) {
    sendServerError(e, "QST-DET");
  }
});

const BATCH_SIZE = 30;
let step = 0;

async function getQuestionList(examId) {
  while (await nextQuestionBatch(examId, step)) {
    step++;
    await delay(500);
  }
}

async function nextQuestionBatch(examId, step) {
  try {
    let response = await MainRepository.questionWithAnswersList({
      exams: examId,
      limit: BATCH_SIZE,
      offset: step * BATCH_SIZE,
    });
    if (questions.value === null) {
      questions.value = [];
    }
    questions.value.push(...response.data.results);
    return response.data.next !== null;
  } catch (e) {
    sendServerError(e, "QST-DET");
  }
}

function delay(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}
</script>

<template>
  <title-header-view>
    <template v-slot:header>
      <p class="mb-2">
        Vista Previa examen: <span v-if="exam !== null">{{ exam.title }}</span>
      </p>
      <p class="text-xl">
        Oposiciones: <span v-if="exam !== null">{{ exam.oppositionNames.join(", ") }}</span>
      </p>
    </template>
    <base-card>
      <fade-transition>
        <div v-if="questions !== null">
          <question-preview-body
            v-for="(question, index) in questions"
            :key="question.id"
            :question-id="question.id"
            :question-number="index + 1"
            :question-object="question"
            is-in-exam-preview
            class="my-1.5y"
          >
          </question-preview-body>
        </div>
        <div v-else>
          <question-dummy></question-dummy>
        </div>
      </fade-transition>
      <div class="my-3 flex flex-row justify-end">
        <back-button class="mr-20">volver</back-button>
      </div>
    </base-card>
  </title-header-view>
</template>
