import { createRouter, createWebHistory } from "vue-router";
import { routes } from "@/router/routes.js";
import MainRepository from "@/repositories/MainRepository.js";
import { useToast } from "@/components/composables/notifications.js";

const { sendServerError } = useToast();

export function buildRouter(user) {
  const router = createRouter({
    scrollBehavior(to, from, savedPosition) {
      return new Promise((resolve, reject) => {
        setTimeout(() => {
          if (savedPosition) {
            resolve(savedPosition);
          } else {
            resolve({ left: 0, top: 0 });
          }
        }, 500);
      });
    },
    history: createWebHistory(import.meta.env.BASE_URL),
    routes: routes,
  });

  router.beforeEach(async (to) => {
    try {
      let response = await MainRepository.me();
      user.value = response.data;
      // When user does not have a valid payment, redirects to the payment page
      // if (
      //   !user.value.isStaff &&
      //   !user.value.isSuperuser &&
      //   user.value.statusInPlatform === "pending_first_payment" &&
      //   to.name !== "payment"
      // ) {
      //   return { name: "payment" };
      // }
      // Redirects if trying to access to payment page when not pending_first_payment
      if (user.value.statusInPlatform !== "pending_first_payment" && to.name === "payment") {
        return { name: "home" };
      }
      return true;
    } catch (error) {
      sendServerError(error);
    }
  });

  return router;
}
