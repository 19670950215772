<script setup>
import { watchDebounced } from "@vueuse/core";
import { ref } from "vue";

const props = defineProps({
  modelValue: {
    type: [String, Number],
    default: null,
  },
  id: {
    type: String,
    default: "id-textarea",
  },
  name: {
    type: String,
    default: "textarea",
  },
  placeholder: {
    type: String,
    default: "",
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  initialValue: {
    type: String,
    default: "",
  },
});
const emit = defineEmits(["update:modelValue", "debouncedInput"]);

// vue component send the update:modelValue on change. This sent the value only when input lost focus
// there are some components that rely on this behaviour.
// So for those who need to listen to changes faster, but debounced, we offer this
// to provide initial value use initialValue instead of modelValue to avoid conflicts
const mostRecentValue = ref(props.modelValue);
watchDebounced(
  mostRecentValue,
  (newValue) => {
    emit("debouncedInput", newValue);
  },
  { debounce: 500 }
);

function processInput(value) {
  mostRecentValue.value = value;
}
</script>
<template>
  <input
    :value="modelValue || initialValue"
    @change="emit('update:modelValue', $event.target.value)"
    @input="processInput($event.target.value)"
    :id="id"
    :name="name"
    :placeholder="placeholder"
    :required="required"
    :disabled="disabled"
    class="mt-1 block w-full rounded-md border border-secondary-100 p-2 shadow-sm focus:border-primary-600 focus:ring-primary-600 sm:text-sm"
  />
</template>
