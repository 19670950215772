<script setup>
import { computed } from "vue";
import _ from "lodash";
import BasePaginationButton from "@/components/buttons/BasePaginationButton.vue";

const props = defineProps({
  page: {
    type: [String, Number],
    required: true,
  },
  pageSize: {
    type: [String, Number],
    required: true,
  },
  totalResults: {
    type: Number,
    required: true,
  },
  maxPageButtons: {
    type: Number,
    default: 5,
  },
  showCurrent: {
    type: Boolean,
    default: true,
  },
});

const noPages = computed(() => {
  if (props.pageSize > 0) {
    return _.ceil(props.totalResults / props.pageSize);
  }
  return 1;
});

const currentPageSize = computed(() => {
  if (props.page === noPages.value) {
    return props.totalResults - props.pageSize * (noPages.value - 1);
  }
  return props.pageSize;
});

const halfMaxPageButtons = computed(() => {
  return _.floor(props.maxPageButtons / 2);
});

const visiblePages = computed(() => {
  if (noPages.value > props.maxPageButtons) {
    const array = [];
    const start = props.page - halfMaxPageButtons.value > 1 ? props.page - halfMaxPageButtons.value : 1;
    const end =
      props.page + halfMaxPageButtons.value < noPages.value ? props.page + halfMaxPageButtons.value : noPages.value;
    for (let i = start; i <= end; i++) {
      array.push(i);
    }
    return array;
  }
  return noPages.value;
});

const from = computed(() => {
  return (props.page - 1) * props.pageSize + 1;
});

const to = computed(() => {
  let value = props.page * props.pageSize;
  if (value > props.totalResults) {
    return props.totalResults;
  }
  return value;
});

const emit = defineEmits(["page"]);

function setPage(page) {
  emit("page", page);
}
</script>

<template>
  <div class="flex items-center justify-end">
    <p class="mx-5 text-sm text-secondary-400">Mostrando desde {{ from }} hasta {{ to }} de {{ totalResults }}</p>
    <base-pagination-button :value="1" :disabled="page === 1" @set-page="setPage">&lt;&lt;</base-pagination-button>
    <base-pagination-button :value="page - 1" :disabled="page === 1" @set-page="setPage">&lt;</base-pagination-button>

    <base-pagination-button v-show="page > 1 + halfMaxPageButtons" :value="null" :disabled="true"
      >...
    </base-pagination-button>

    <base-pagination-button
      v-for="goToPage in visiblePages"
      :key="goToPage"
      :value="goToPage"
      :active="goToPage === page"
      @set-page="setPage"
      >{{ goToPage }}
    </base-pagination-button>

    <base-pagination-button v-show="page < noPages - halfMaxPageButtons" :value="null" :disabled="true"
      >...
    </base-pagination-button>

    <base-pagination-button :value="page + 1" :disabled="page >= noPages" @set-page="setPage"
      >&gt;
    </base-pagination-button>
    <base-pagination-button :value="noPages" :disabled="page >= noPages" @set-page="setPage"
      >&gt;&gt;
    </base-pagination-button>

    <p v-show="showCurrent" class="mx-3">Mostrando {{ currentPageSize }} de {{ totalResults }}</p>
  </div>
</template>

<style scoped></style>
