<script setup>
import { ref } from "vue";
import { useToast } from "@/components/composables/notifications.js";
import StaffRepository from "@/repositories/StaffRepository.js";
import BaseInput from "@/components/forms/BaseInput.vue";

defineProps({
  id: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(["userId"]);

const { sendServerError } = useToast();

const selectedUser = ref(null);
const userList = ref([]);
const query = ref("");

const searchUser = async (newQuery) => {
  query.value = newQuery;
  try {
    let data = await StaffRepository.userList({ query: query.value });
    userList.value = data.data.results.map((u) => u.user).filter((u) => u !== null);
  } catch (e) {
    sendServerError(e);
  }
};

const selectUser = (user) => {
  selectedUser.value = user;
  userList.value = [];
  if (selectedUser.value) {
    query.value = `${selectedUser.value.firstName} ${selectedUser.value.lastName}`;
  }
  emit("userId", selectedUser.value.id);
};

const deselectUser = () => {
  selectedUser.value = null;
  query.value = null;
  emit("userId", null);
};
</script>

<template>
  <div>
    <div class="flex items-center justify-center gap-3">
      <base-input
        :model-value="query"
        :id="id"
        class="w-[40rem] border-secondary-200 bg-background text-secondary-600"
        :name="id"
        @debounced-input="searchUser"
      />
      <div class="inline cursor-pointer font-bold" @click="deselectUser">X</div>
    </div>
    <p
      class="rounded bg-white/75 p-2 hover:cursor-pointer hover:bg-secondary-100"
      v-for="user in userList"
      :key="user.id"
      @click="selectUser(user)"
    >
      {{ user.firstName }} {{ user.lastName }}
    </p>
  </div>
</template>

<style scoped></style>
