<script setup>
import BaseCard from "@/components/BaseCard.vue";
import { computed, ref } from "vue";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import RichTextarea from "@/components/forms/RichTextarea.vue";
import { useRouter } from "vue-router";
import { useWS } from "@/components/composables/ws-call.js";
import MainRepository from "@/repositories/MainRepository.js";
import { EXAM_TITLES } from "@/components/utils.js";
import BaseTextarea from "@/components/forms/BaseTextarea.vue";
import BackButton from "@/components/buttons/BackButton.vue";
import BaseButton from "@/components/buttons/BaseButton.vue";
import BaseLoading from "@/components/BaseLoading.vue";
import { useToast } from "@/components/composables/notifications.js";
import TitleHeaderView from "@/layout-components/TitleHeaderView.vue";
import BaseSelect from "@/components/forms/BaseSelect.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import QuestionOppositionSelection from "@/components/imported-questions/QuestionOppositionSelection.vue";

const router = useRouter();
const { sendServerError, sendNotification } = useToast();

const { callWs: create, record: result } = useWS(MainRepository.createExamDetail());

const props = defineProps({
  examType: {
    type: String,
    required: true,
  },
});

const onAction = ref(false);
const record = ref({
  oppositions: [],
  title: "",
  examType: props.examType,
  order: 0,
  statement: "",
  examCategory: null,
});

const title = computed(() => {
  return EXAM_TITLES[props.examType];
});

const examCategoryOptions = computed(() => {
  return [
    { label: "Ninguna", value: "" },
    { label: "Parte General", value: "parte general" },
    { label: "Orden Civil", value: "orden civil" },
    { label: "Orden Penal", value: "orden penal" },
    { label: "Orden Administrativo", value: "orden administrativo" },
    { label: "Orden Social", value: "orden social" },
  ];
});

const showStatement = computed(() => {
  if (record.value != null) {
    return record.value.examType !== "previousCall";
  }
  return false;
});

async function submit() {
  onAction.value = true;
  try {
    const opoArray = record.value.oppositions.map((obj) => obj.opposition);
    const data = { ...record.value, oppositions: [...opoArray] };
    await create(data);
    sendNotification(null, "Éxito", "Examen guardado correctamente");
  } catch (e) {
    sendServerError(e, "EXAM-UPD");
  }
  onAction.value = false;
}

async function saveAndExit() {
  if (record.value.oppositions === null || record.value.oppositions.length === 0) {
    sendNotification("error", "Parámetros inválidos", "Por favor, seleccione una oposición");
    return;
  }
  if (record.value.title.trim().length <= 5) {
    sendNotification("error", "Parámetros inválidos", "Por favor, escriba un título válido");
    return;
  }

  try {
    await submit();
    await router.push({ name: "exam-detail", params: { examId: result.value.id } });
  } catch (error) {
    console.log(error);
  }
}

function valueUpdated(newValue) {
  record.value.statement = newValue;
}
</script>
<template>
  <title-header-view>
    <template v-slot:header>Edición</template>
    <base-card class="mb-8">
      <form @submit.prevent="submit">
        <div class="overflow-hidden bg-white">
          <div class="px-4 py-5 sm:px-6">
            <h3 class="text-lg font-medium leading-6 text-secondary-800">{{ title }}</h3>
          </div>
          <fade-transition>
            <div>
              <div class="border-t border-secondary-200">
                <div>
                  <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-secondary-600">Oposición</dt>
                    <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                      <question-opposition-selection
                        v-model="record.oppositions"
                        allow-add-message="Para poder añadir oposiciones es necesario que el examen no tenga modificaciones desde la publicación"
                        :select-themes="false"
                        :allow-add="true"
                      />
                    </dd>
                  </div>
                  <div class="bg-secondary-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-secondary-600">Título</dt>
                    <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                      <base-textarea v-model="record.title"></base-textarea>
                      <small>Mínimo 5 letras</small>
                    </dd>
                  </div>
                  <div v-if="showStatement" class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-secondary-600">Enunciado</dt>
                    <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                      <rich-textarea
                        :value="record.statement"
                        id="statement"
                        @value-updated="valueUpdated"
                      ></rich-textarea>
                    </dd>
                  </div>
                  <div v-if="showStatement" class="bg-secondary-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-secondary-600">Categoría</dt>
                    <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                      <div class="fw-full lex mt-1">
                        <base-select
                          v-model="record.examCategory"
                          id="select-exam-category"
                          name="exam-category"
                          :options="examCategoryOptions"
                        />
                      </div>
                    </dd>
                  </div>
                  <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
                    <dt class="text-sm font-medium text-secondary-600">Orden</dt>
                    <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
                      <base-input v-model="record.order" id="order"></base-input>
                    </dd>
                  </div>
                </div>
              </div>
              <div class="mt-3 flex flex-row justify-center">
                <base-button class="ml-2" :disabled="onAction" @click.prevent="saveAndExit">Crear</base-button>
                <back-button class="ml-2" :disabled="onAction">Cancelar</back-button>
                <base-loading v-show="onAction" :size="6" class="ml-2 mt-1"></base-loading>
              </div>
            </div>
          </fade-transition>
        </div>
      </form>
    </base-card>
  </title-header-view>
</template>
