<script setup>
const props = defineProps({
  modelValue: {
    default: null,
  },
  id: {
    type: String,
    default: "select",
  },
  name: {
    type: String,
    default: "select",
  },
  options: {
    type: Array,
    required: true,
  },
  showBlankOption: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  emitEmptyAsNull: {
    type: Boolean,
    default: true,
  },
  convertToBool: {
    type: Boolean,
    required: false,
  },
});
const emit = defineEmits(["update:modelValue"]);

const emitValue = (event) => {
  if (props.emitEmptyAsNull) {
    emit("update:modelValue", event.target.value === "" ? null : props.options[event.target.value].value);
  } else {
    emit("update:modelValue", props.options[event.target.value].value);
  }
};
</script>

<template>
  <select
    :id="id"
    :name="name"
    :required="required"
    :disabled="disabled"
    class="block w-full rounded-lg border border-secondary-200 bg-background p-2 text-sm text-secondary-600 focus:border-primary-600 focus:ring-primary-600"
    @change="emitValue"
  >
    <option v-if="showBlankOption" value="">----</option>
    <option
      v-for="(option, index) in options"
      :key="index"
      :value="option.value === null ? '' : index"
      :selected="modelValue !== null && option.value === modelValue"
    >
      {{ option.label }}
    </option>
  </select>
</template>
