<script setup>
import AttemptSelectorHeader from "@/components/attempt-selector/AttemptSelectorHeader.vue";

defineProps({
  publicExamination: {
    type: Object,
    required: true,
  },
  selectedType: {
    type: String,
    required: true,
  },
  selectedDifficulty: {
    type: [String, null],
    required: true,
  },
  questions: {
    type: [Number, null],
    required: true,
  },
  selectedContents: {
    type: [Object, null],
    required: true,
  },
  selectedExam: {
    type: [Object, null],
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits([
  "unselect-public-examination",
  "unselect-type",
  "unselect-exam",
  "unselect-params",
  "unselect-contents",
  "create",
]);

function unselectPublicExamination() {
  emit("unselect-public-examination");
}

function unselectType() {
  emit("unselect-type");
}

function unselectExam() {
  emit("unselect-exam");
}

function unselectParams() {
  emit("unselect-params");
}

function unselectContents() {
  emit("unselect-contents");
}

function create() {
  emit("create");
}
</script>

<template>
  <div>
    <attempt-selector-header
      :public-examination="publicExamination"
      :disabled="disabled"
      :selected-exam="selectedExam"
      :selected-type="selectedType"
      :selected-difficulty="selectedDifficulty"
      :questions="questions"
      :selected-contents="selectedContents"
      :show-create-button="true"
      @unselect-public-examination="unselectPublicExamination"
      @unselect-type="unselectType"
      @unselect-exam="unselectExam"
      @unselect-params="unselectParams"
      @unselect-contents="unselectContents"
      @create="create"
    />
  </div>
</template>
