<script setup></script>

<template>
  <button
    class="flex justify-center text-base font-light text-secondary-800 underline hover:text-secondary-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-secondary-400 focus-visible:ring-opacity-75 disabled:bg-secondary-400"
  >
    <slot name="icon"></slot>
    <span class="underline"><slot></slot></span>
    <slot name="rightIcon"></slot>
  </button>
</template>
