<script setup>
import { useRoute } from "vue-router";
import { computed } from "vue";
import UserAcademySubscriptions from "@/components/staff/user/UserAcademySubscriptions.vue";
import UserPaidSubscriptions from "@/components/staff/user/UserPaidSubscriptions.vue";
import UserProfile from "@/components/staff/user/UserProfile.vue";

const route = useRoute();

const wopoUserId = computed(() => {
  return route.params.id;
});
</script>
<template>
  <div>
    <user-profile :wopo-user-id="wopoUserId" />
    <user-academy-subscriptions :wopo-user-id="wopoUserId" />
    <user-paid-subscriptions :wopo-user-id="wopoUserId" />
  </div>
</template>
