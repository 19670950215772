<script setup>
import { computed } from "vue";
import QuestionAttemptDummy from "@/components/attempt-details/QuestionAttemptDummy.vue";

const questions = computed(() => [{}, {}, {}, {}, {}, {}]);
</script>
<template>
  <div>
    <question-attempt-dummy v-for="(question, index) in questions" :key="index" :index="index + 1" />
  </div>
</template>
