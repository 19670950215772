<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="25.269"
      height="24.22"
      viewBox="0 0 25.269 24.22"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectángulo_317"
            data-name="Rectángulo 317"
            width="25.269"
            height="24.22"
            transform="translate(0 0)"
          />
        </clipPath>
      </defs>
      <g id="Grupo_243" data-name="Grupo 243" transform="translate(0 0)">
        <g id="Grupo_242" data-name="Grupo 242" clip-path="url(#clip-path)">
          <path
            id="Trazado_7516"
            data-name="Trazado 7516"
            d="M25.213,9.147l-.007-.021a1.95,1.95,0,0,0-1.7-1.21l-6.35-.923L14.326,1.252A1.962,1.962,0,0,0,12.623,0a1.953,1.953,0,0,0-1.675,1.239L8.109,6.993l-6.336.921A1.963,1.963,0,0,0,.056,9.147a1.947,1.947,0,0,0,.661,1.975l4.6,4.48-1.085,6.31a2.04,2.04,0,0,0,.363,1.755,1.57,1.57,0,0,0,1.2.553H5.82a2.468,2.468,0,0,0,1.133-.312l5.681-2.989L18.311,23.9a2.5,2.5,0,0,0,1.137.313h.03a1.577,1.577,0,0,0,1.2-.56,2.031,2.031,0,0,0,.36-1.736L19.955,15.6l4.585-4.47a1.953,1.953,0,0,0,.673-1.982M5.964,22.22l1.163-6.782a.885.885,0,0,0-.254-.781l-4.93-4.8a.786.786,0,0,1-.13-.146.768.768,0,0,1,.2-.044l6.808-.99a.884.884,0,0,0,.665-.482l3.05-6.182a.845.845,0,0,1,.1-.169.827.827,0,0,1,.1.177l3.045,6.17a.884.884,0,0,0,.664.482l6.818.991a.742.742,0,0,1,.192.043.724.724,0,0,1-.131.15l-.3.263h.03l-4.662,4.543a.879.879,0,0,0-.254.781L19.3,22.228a.773.773,0,0,1,.018.191.738.738,0,0,1-.187-.083l-6.091-3.2a.885.885,0,0,0-.821,0l-6.1,3.2a.724.724,0,0,1-.178.078.8.8,0,0,1,.019-.2"
            fill="#bbc1c5"
          />
        </g>
      </g>
    </svg>
  </div>
</template>

<style scoped>
path {
}

.starred path {
  fill: #ffd100;
}
</style>
