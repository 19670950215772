<script setup>
import BaseCard from "@/components/BaseCard.vue";
import AttemptSelectorTypeElement from "@/components/attempt-selector/AttemptSelectorTypeElement.vue";

import {
  ATTEMPT_CUSTOM,
  ATTEMPT_CUSTOM_BLANK,
  ATTEMPT_CUSTOM_FAVOURITES,
  ATTEMPT_CUSTOM_INCORRECT,
  ATTEMPT_CUSTOM_UNCERTAIN,
  ATTEMPT_PRACTICAL_CASE,
  ATTEMPT_PRACTICAL_CASE_CC,
  ATTEMPT_PRACTICAL_CASE_PREVIOUS_CALL,
  ATTEMPT_PRACTICAL_CASE_PREVIOUS_CALL_CC,
  ATTEMPT_PREVIOUS_CALL,
  ATTEMPT_PREVIOUS_CALL_CC,
  ATTEMPT_BY_GENERAL_PROCESAL,
  ATTEMPT_TYPE_TITLES,
} from "@/components/utils.js";
import { computed } from "vue";
import { attemptTypeIconSelector } from "@/components/icons.js";
import AttemptSelectorHeader from "@/components/attempt-selector/AttemptSelectorHeader.vue";

const props = defineProps({
  publicExamination: {
    type: Object,
    required: true,
  },
  examStatistics: {
    type: [Object, null],
    required: true,
  },
});

const attemptTypes = computed(() => {
  const attempts = [
    {
      title: "Bolsa de preguntas y exámenes oficiales",
      color: "green",
      elements: [
        {
          type: ATTEMPT_CUSTOM,
          description: "Crea test personalizados, elige el número de preguntas y selecciona el contenido",
        },
        {
          type: ATTEMPT_BY_GENERAL_PROCESAL,
          description: "Crea test con preguntas de repaso, el número de preguntas y selecciona el contenido",
        },
      ],
    },
    {
      title: "De tu histórico de test realizados",
      color: "orange",
      elements: [
        {
          type: ATTEMPT_CUSTOM_INCORRECT,
          description: "Aquellas falladas",
        },
        {
          type: ATTEMPT_CUSTOM_BLANK,
          description: "Aquellas en blanco",
        },
        {
          type: ATTEMPT_CUSTOM_FAVOURITES,
          description: "Aquellas marcadas como importante",
        },
        {
          type: ATTEMPT_CUSTOM_UNCERTAIN,
          description: "Aquellas dudosas",
        },
      ],
    },
  ];

  if (props.examStatistics != null) {
    if (props.examStatistics[ATTEMPT_PREVIOUS_CALL_CC] > 0) {
      attempts[0].elements.push({
        type: ATTEMPT_PREVIOUS_CALL,
        description: "Test reales de convocatorias anteriores, siempre actualizados",
      });
    }
    if (
      props.examStatistics[ATTEMPT_PRACTICAL_CASE_CC] > 0 ||
      props.examStatistics[ATTEMPT_PRACTICAL_CASE_PREVIOUS_CALL_CC] > 0
    ) {
      const exams = {
        title: "Especial supuestos prácticos",
        color: "red",
        elements: [],
      };
      if (props.examStatistics[ATTEMPT_PRACTICAL_CASE_CC] > 0) {
        exams.elements.push({
          type: ATTEMPT_PRACTICAL_CASE,
          description: "Prueba nuestros supuestos prácticos ",
        });
      }
      if (props.examStatistics[ATTEMPT_PRACTICAL_CASE_PREVIOUS_CALL_CC] > 0) {
        exams.elements.push({
          type: ATTEMPT_PRACTICAL_CASE_PREVIOUS_CALL,
          description: "Supuestos prácticos de exámenes reales de convocatorias anteriores",
        });
      }

      attempts.push(exams);
    }
  }

  return attempts;
});

const emit = defineEmits(["unselect-public-examination", "select-type"]);

function unselectPublicExamination() {
  emit("unselect-public-examination");
}

function selectType(type) {
  emit("select-type", type);
}

function sectionTitle(type) {
  let result = ATTEMPT_TYPE_TITLES.filter((obj) => {
    return obj.value === type;
  });
  return result[0].title;
}

function selectIcon(type) {
  return attemptTypeIconSelector(type);
}
</script>

<template>
  <div>
    <attempt-selector-header
      :public-examination="publicExamination"
      :selected-exam="null"
      :selected-type="null"
      :selected-difficulty="null"
      :questions="null"
      :selected-contents="null"
      @unselect-public-examination="unselectPublicExamination"
    />

    <base-card v-for="(card, index) in attemptTypes" :key="index" class="mt-4">
      <h3 class="mb-7 ml-5 mt-3 text-xl">{{ card.title }}</h3>
      <attempt-selector-type-element
        v-for="(element, elementIndex) in card.elements"
        :key="elementIndex"
        :type="element.type"
        :circle-color="card.color"
        :description="element.description"
        @select-type="selectType"
      >
        {{ sectionTitle(element.type) }}
        <template v-slot:icon>
          <component v-bind:is="selectIcon(element.type)" class="w-full" />
        </template>
      </attempt-selector-type-element>
    </base-card>
  </div>
</template>
