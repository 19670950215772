<script setup></script>

<template>
  <div>
    <h2 class="mb-3 flex items-center text-3xl font-bold">Requisitos del Documento</h2>
    <hr class="mb-3" />
    <div class="text-left">
      <div class="mb-3">
        <dd>
          Los archivos que se suban deben cumplir con las siguientes características para que la importación funcione
          correctamente:
        </dd>
      </div>
      <div class="mb-3">
        <dt class="text-xl font-bold">Formato del archivo</dt>
        <dd>Los documentos deben estar en formato <strong>PDF</strong>.</dd>
      </div>
      <div class="mb-3">
        <dt class="text-xl font-bold">Pie de página</dt>
        <dd>
          Todas las páginas del PDF deben tener un pie de página que comience con el formato
          <em>"Fecha versión XX/XX/XXXX"</em>.
        </dd>
      </div>
      <div class="mb-3">
        <dt class="text-xl font-bold">Respuestas</dt>
        <dd>
          Todas las respuestas deben estar
          <strong>exclusivamente en la última página del PDF</strong> y deben estar contenidas en una única página.
        </dd>
      </div>
    </div>

    <h2 class="mb-3 flex items-center text-3xl font-bold">Requisitos adicionales según el tipo de documento</h2>
    <hr class="mb-3" />
    <div class="text-left">
      <div class="mb-3">
        <dt class="text-xl font-bold">Supuestos prácticos y supuestos prácticos oficiales</dt>
        <dd>
          Cada página debe tener una cabecera que termine con el formato <em>"Práctico N-X"</em>, donde
          <strong>X</strong> es un número cualquiera.
        </dd>
      </div>
      <div class="mb-3">
        <dt class="text-xl font-bold">Banco de preguntas, Banco de preguntas: Preguntas de repaso y Test oficiales</dt>
        <dd>
          Si la cabecera de cada página termina con el formato <em>"Simulacro N-X"</em>, donde <strong>X</strong> es un
          número cualquiera, se eliminará este texto de las cabeceras y no generará errores en las preguntas.
        </dd>
        <dd>
          En caso de no cumplir con este formato, el documento podrá ser importado a la plataforma, pero el texto del
          encabezado <strong>NO se eliminará</strong> automáticamente, lo que podría generar preguntas erróneas. En ese
          caso, el usuario deberá revisar manualmente las preguntas y corregir los errores.
        </dd>
      </div>
      <div class="mb-3">
        <h2 class="mb-3 flex items-center text-3xl font-bold">Importación de Documentos Duplicados</h2>
        <hr class="mb-3" />
        <dd>
          Si el documento ya ha sido importado antes, se mostrará un mensaje indicando el problema. Sin embargo, se
          permitirá continuar con la importación, lo cuál podría generar <strong>duplicados</strong> de las preguntas o
          exámenes en la plataforma.
        </dd>
        <dd>Revisar cuidadosamente para evitar confusiones o contenido duplicado.</dd>
      </div>
    </div>
    <p class="mb-3">
      <em>
        Es responsabilidad del usuario asegurarse de que los documentos cumplen con los formatos requeridos para evitar
        errores en la importación.
      </em>
    </p>
  </div>
</template>

<style scoped></style>
