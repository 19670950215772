<script setup>
import { useRoute } from "vue-router";
import { useWS } from "@/components/composables/ws-call.js";
import MainRepository from "@/repositories/MainRepository.js";
import { useToast } from "@/components/composables/notifications.js";
import { computed, onMounted } from "vue";
import TitleHeaderView from "@/layout-components/TitleHeaderView.vue";
import BaseCard from "@/components/BaseCard.vue";
import { formatDateTime } from "@/components/utils.js";
import QuestionAttemptAnswer from "@/components/attempt-details/QuestionAttemptAnswer.vue";

const route = useRoute();
const { sendServerError } = useToast();

const { callWs, record } = useWS(() => MainRepository.notificationDetail(route.params.notificationId));

const title = computed(() => {
  if (record.value != null) return record.value.title;
  return "";
});
const deprecatedQuestionStatement = computed(() => {
  if (record.value != null) return record.value.deprecatedQuestion.statement;
  return "";
});
const deprecatedMessage = computed(() => {
  if (record.value != null) return record.value.deprecatedQuestion.deprecatedMessage;
  return "";
});
const question = computed(() => {
  if (record.value != null) return record.value.deprecatedQuestion;
  return "";
});
const createdAt = computed(() => {
  if (record.value != null) return formatDateTime(record.value.createdAt);
  return "";
});

const publicExaminationNames = computed(() => {
  if (record.value && record.value.deprecatedQuestion && record.value.deprecatedQuestion.publicExaminations) {
    return record.value.deprecatedQuestion.publicExaminations.map((exam) => exam.shortName).join(", ");
  }
  return "";
});
const hasMultiplePublicExamination = computed(() => {
  return (
    record.value && record.value.deprecatedQuestion && record.value.deprecatedQuestion.publicExaminations.length > 1
  );
});

onMounted(async () => {
  await fetchData();
  if (record.value && !record.value.wasRead) {
    try {
      await MainRepository.markNotificationAsSeen(record.value.id);
    } catch (e) {
      sendServerError(e, "UPDATE-NOTIFY");
    }
  }
});

async function fetchData() {
  record.value = null;
  try {
    await callWs({});
  } catch (e) {
    sendServerError(e, "NOTIFY-DET");
  }
}
</script>

<template>
  <title-header-view>
    <div>
      <base-card class="mb-8 p-0 pb-2">
        <p class="mx-5 pb-5 pt-3 text-sm text-gray-600">{{ createdAt }}</p>
        <div class="flex flex-row border-b-2 border-b-secondary-50 px-0 pb-5 pt-3">
          <h3 class="mx-5 mt-3 text-secondary-800">
            <span class="font-bold text-secondary-800">
              {{ title }}
            </span>
            <p v-if="!hasMultiplePublicExamination" class="mt-4 text-secondary-800">
              Esta pregunta percenecía a la oposición {{ publicExaminationNames }}
            </p>
            <p v-else class="mt-4 text-secondary-800">
              Esta pregunta percenecía a las oposiciones {{ publicExaminationNames }}
            </p>
          </h3>
        </div>
        <div class="text-md mx-5 mt-4 p-2 text-secondary-800">
          <div class="mx-4 mb-4 mt-3 h-3 w-3/4">{{ deprecatedQuestionStatement }}</div>
        </div>

        <div class="p-4">
          <question-attempt-answer
            v-for="(answer, index) in question.answers"
            :id="id + '-answer-' + answer.id"
            :name="name + '-answer'"
            :index="index"
            :key="answer.id"
            :input-value="answer.id"
            :label="answer.statement"
            :disabled="true"
            :show-result="true"
            :can-select-answer="false"
          />
        </div>
        <div class="mx-5 mb-3 px-3 py-1">
          <h4 v-if="deprecatedMessage !== ''">Explicación de impugnación</h4>
          <h4 v-else class="mb-2 font-medium text-secondary-600">No existe mensaje de impugación</h4>
          <div v-if="deprecatedMessage !== ''" class="border border-secondary-100 p-4">
            {{ deprecatedMessage }}
          </div>
        </div>
      </base-card>
    </div>
  </title-header-view>
</template>
