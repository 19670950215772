<script setup>
import { useWS } from "@/components/composables/ws-call.js";
import StaffRepository from "@/repositories/StaffRepository.js";
import BaseCard from "@/components/BaseCard.vue";
import { inject, onMounted, ref } from "vue";
import BaseLabel from "@/components/forms/BaseLabel.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import BaseComplexSelect from "@/components/forms/BaseComplexSelect.vue";
import { usePagination } from "@/components/composables/ws-pagination.js";
import ButtonSecondary from "@/components/buttons/ButtonSecondary.vue";
import { TrashIcon } from "@heroicons/vue/24/outline";
import { useToast } from "@/components/composables/notifications.js";
import { useRouter } from "vue-router";

const { sendError, sendServerError, sendWarnNotification, sendNotification } = useToast();
const router = useRouter();

const user = inject("user");
const academy = ref(null);
const email = ref(null);
const selectedEmails = ref([]);
const publicExamination = ref([]);
const selectedPublicExaminations = ref([]);

const { callWs: createInvites, error: createInvitesResponse, erroMsg } = useWS(StaffRepository.createInvites());

const { items: academies, getList } = usePagination(StaffRepository.academyList, 100, 0);
const { items: publicExaminations, getList: getPublicExaminationList } = usePagination(
  StaffRepository.publicExaminationList,
  100,
  0
);

onMounted(async () => {
  try {
    await getList();
    await getPublicExaminationList();
  } catch (error) {
    sendServerError(error, "IN-INIT-DATA");
  }
});

function deleteEmail(index) {
  selectedEmails.value.splice(index, 1);
}

function addEmail() {
  if (email.value == null || email.value.trim() === "") {
    sendWarnNotification("Email inválido", "Por favor, introduzca un email válido");
  } else if (selectedEmails.value.indexOf(email.value) !== -1) {
    sendWarnNotification("Email ya añadido", "Por favor, introduzca otro email");
  } else {
    selectedEmails.value.push(email.value);
    email.value = null;
  }
}

function deletePublicExamination(index) {
  selectedPublicExaminations.value.splice(index, 1);
}

function addPublicExamination() {
  if (publicExamination.value == null) {
    sendWarnNotification("Selección invalida", "Por favor, seleccione una oposición");
  } else if (
    selectedPublicExaminations.value.find((item) => {
      return item.id === publicExamination.value.id;
    }) != null
  ) {
    sendWarnNotification("Selección ya añadida", "Por favor, seleccione otra oposición");
  } else {
    selectedPublicExaminations.value.push(publicExamination.value);
    publicExamination.value = null;
  }
}

async function sendInvites() {
  if ((user.value.isStaff || user.value.isSuperuser) && academy.value == null) {
    sendWarnNotification("Datos erróneos", "Por favor, seleccione una academia");
  } else if (selectedEmails.value.length === 0) {
    sendWarnNotification("Datos erróneos", "Por favor, añada al menos un email");
  } else if (selectedPublicExaminations.value.length === 0) {
    sendWarnNotification("Datos erróneos", "Por favor, seleccione al menos una oposición");
  } else {
    try {
      await createInvites({
        academy: academy.value.id,
        emails: selectedEmails.value,
        publicExaminations: selectedPublicExaminations.value.map((item) => item.id),
      });
      if (createInvitesResponse.value === 400) {
        sendWarnNotification("Error", erroMsg.value);
      } else {
        sendNotification("success", "Éxito", "Notificaciones enviadas correctamente");
        router.push({ name: "manage-users" });
      }
    } catch (e) {
      if (e.response && e.response.data && e.response.data.emails) {
        sendError(e.response.data.emails.map((e) => e.message).join(", "));
      } else {
        sendServerError("Error enviando invitaciones");
      }
    }
  }
}
</script>
<template>
  <div>
    <base-card>
      <form @submit.prevent="sendInvites">
        <div class="mx-2 my-4 flex flex-col">
          <h2 class="text-2xl font-semibold text-secondary-800">Invitar usuarios</h2>
          <div class="mt-4 max-w-[40rem]" v-if="user.isStaff || user.isSuperuser">
            <base-label label="Academia" for-label="select-academy" />
            <base-complex-select
              v-model="academy"
              id="select-academy"
              name="select-academy"
              :value-option="(item) => item.id"
              :label-option="(item) => item.name"
              :options="academies"
              show-blank-option
            >
              <template v-slot:blankOption> Sin asignar</template>
            </base-complex-select>
          </div>

          <div class="mt-4 w-[30rem]">
            <base-label label="Email" for-label="input-email" />
            <div class="flex flex-row">
              <base-input
                class="w-full border-secondary-200 bg-background text-secondary-600"
                v-model="email"
                id="input-email"
                name="input-email"
              />
              <div>
                <button-secondary class="ml-4 mt-1 !py-1.5" @click.prevent="addEmail">Añadir</button-secondary>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <base-label label="Email seleccionados" />
            <div class="mt-2">
              <ul class="flex">
                <li
                  v-for="(email, key) in selectedEmails"
                  :key="key"
                  class="mb-1 mr-2 flex rounded-full border border-primary-800 px-4 py-2"
                >
                  <span class="mt-1">
                    {{ email }}
                  </span>
                  <div class="">
                    <button
                      @click.prevent="deleteEmail(key)"
                      class="rounded p-1.5 hover:bg-primary-100"
                      title="Borrar email"
                    >
                      <TrashIcon class="block h-5 w-5" />
                    </button>
                  </div>
                </li>
                <li
                  v-show="selectedEmails.length === 0"
                  class="mb-1 mr-2 flex rounded-full border border-primary-800 px-4 py-2"
                >
                  No hay emails seleccionados
                </li>
              </ul>
            </div>
          </div>
          <div class="mt-4 w-[40rem]">
            <base-label label="Oposición" for-label="select-public-examination" />
            <div class="flex flex-row">
              <base-complex-select
                v-model="publicExamination"
                id="select-public-examination"
                name="select-public-examination"
                :value-option="(item) => item.id"
                :label-option="(item) => item.shortName"
                :options="publicExaminations"
                show-blank-option
                @click.prevent
                @change.prevent
              >
                <template v-slot:blankOption> Seleccione oposición</template>
              </base-complex-select>
              <div>
                <button-secondary class="ml-4 mt-1 !py-1.5" @click.prevent="addPublicExamination"
                  >Añadir
                </button-secondary>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <base-label label="Oposiciones seleccionadas" />
            <div class="mt-2">
              <ul class="flex">
                <li
                  v-for="(publicExamination, key) in selectedPublicExaminations"
                  :key="key"
                  class="mb-1 mr-2 flex rounded-full border border-primary-800 px-4 py-2"
                >
                  <span class="mt-1">
                    {{ publicExamination.shortName }}
                  </span>
                  <div class="">
                    <button
                      @click.prevent="deletePublicExamination(key)"
                      class="rounded p-1.5 hover:bg-primary-100"
                      title="Borrar oposición"
                    >
                      <TrashIcon class="block h-5 w-5" />
                    </button>
                  </div>
                </li>
                <li
                  v-show="selectedPublicExaminations.length === 0"
                  class="mb-1 mr-2 flex rounded-full border border-primary-800 px-4 py-2"
                >
                  No hay oposiciones seleccionadas
                </li>
              </ul>
            </div>
          </div>
          <div class="mt-4 text-center">
            <button-secondary class="mr-4">Enviar invitaciones</button-secondary>
          </div>
        </div>
      </form>
    </base-card>
  </div>
</template>
