<script setup></script>

<template>
  <div>
    <b>Republicar</b>: modificación sustancial en el examen. Las preguntas que estaban en el exámen publicado antes de
    la modificación y que se hayan editado, se marcarán como anuladas y se crearán nuevas en su lugar. <br />
    Esto quiere decir que se marcarán las preguntas como anuladas para los intentos ya realizados del examen. En los
    intentos del examen republicado que tomarán los alumnos a partir de ahora, no se mostrará ninguna pregunta anulada.
  </div>
</template>

<style scoped></style>
