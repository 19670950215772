import { computed } from "vue";

export function useVersioning() {
  const isQA = computed(() => {
    return import.meta.env.VITE_IS_QA;
  });

  return {
    isQA,
  };
}
