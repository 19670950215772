import { authCampusAxios } from "./repository";

export default {
  async publicExaminationList(params) {
    return authCampusAxios.get("/administracion/oposiciones/", {
      params: params,
    });
  },
  async publicExaminationStatisctsList(params) {
    return authCampusAxios.get("/administracion/oposiciones/estadisticas/", {
      params: params,
    });
  },
  async userList(params) {
    return authCampusAxios.get("/administracion/usuarios/", {
      params: params,
    });
  },
  userDetail() {
    return function (id) {
      return authCampusAxios.get(`/administracion/usuarios/${id}/`, {});
    };
  },
  createInvites() {
    return function (data) {
      return authCampusAxios.post(`/administracion/usuarios/invitar-usuarios/`, data);
    };
  },
  createUser() {
    return function (data) {
      return authCampusAxios.post(`/administracion/usuarios/crear-usuario/`, data);
    };
  },
  updateUserProfile(id) {
    return function (data) {
      return authCampusAxios.put(`/administracion/usuarios/${id}/perfil/`, data);
    };
  },
  updateUserAdminProps(id) {
    return function (data) {
      return authCampusAxios.put(`/administracion/usuarios/${id}/admin-props/`, data);
    };
  },
  async academySubscriptionList(params) {
    return authCampusAxios.get("/administracion/suscripciones/academia/", {
      params: params,
    });
  },
  newAcademySubscription() {
    return function (data) {
      return authCampusAxios.post(`/administracion/suscripciones/academia/`, data);
    };
  },
  deleteAcademySubscription() {
    return function (id) {
      return authCampusAxios.delete(`/administracion/suscripciones/academia/${id}/`);
    };
  },
  async paidSubscriptionList(params) {
    return authCampusAxios.get("/administracion/suscripciones/pago/", {
      params: params,
    });
  },
  async academyList(params) {
    return authCampusAxios.get("/administracion/academias/", {
      params: params,
    });
  },
};
