<script setup>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";

defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});

function closeModal() {
  emit("close-modal");
}

const emit = defineEmits(["close-modal"]);
</script>
<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="closeModal" class="relative z-10">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-black bg-opacity-25" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="flex w-1/2 transform flex-col overflow-hidden rounded-2xl bg-white p-6 align-middle shadow-xl transition-all"
            >
              <DialogTitle as="h2" class="mb-4 flex justify-center text-2xl font-medium leading-6 text-secondary-800">
                <slot name="header"></slot>
              </DialogTitle>
              <hr v-if="$slots.header" class="mb-5 border" />

              <div class="mb-5 text-lg">
                <slot></slot>
              </div>

              <div class="flex justify-center">
                <slot name="buttons">
                  <button-primary @click="closeModal">Close</button-primary>
                </slot>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
