<script setup>
import MainRepository from "@/repositories/MainRepository.js";
import { computed, onMounted, provide } from "vue";
import { useWS } from "@/components/composables/ws-call.js";
import { useToast } from "@/components/composables/notifications.js";
import FadeTransition from "@/components/transitions/FadeTransition.vue";
import { onBeforeRouteUpdate, useRoute, useRouter } from "vue-router";
import NoHeaderView from "@/layout-components/NoHeaderView.vue";
import ButtonSecondary from "@/components/buttons/ButtonSecondary.vue";
import { ArrowPathIcon } from "@heroicons/vue/24/outline";
import * as Sentry from "@sentry/browser";

const { sendServerError } = useToast();
const route = useRoute();
const router = useRouter();

const { callWs, record } = useWS(MainRepository.publicExaminationDetail());
const { callWs: getAttemptInProgress, record: inProgress } = useWS(MainRepository.attemptInProgress());

provide("public-examination", record);

const title = computed(() => {
  if (record.value == null) {
    return "";
  }
  return record.value.name;
});

const showContinueButton = computed(() => {
  return inProgress.value != null && inProgress.value.uuid != null;
});

async function obtainDetail(slug) {
  try {
    await callWs({ slug: slug });
  } catch (e) {
    sendServerError(e, "PEX-PEND");
  }
}

onBeforeRouteUpdate(async (to, from) => {
  // only fetch the detail if the slug changed
  if (to.params.slug !== from.params.slug) {
    try {
      await obtainDetail(to.params.slug);
    } catch (error) {
      sendServerError(error);
    }
  }
});

onMounted(async () => {
  await obtainDetail(route.params.slug);
  if (!record.value) {
    Sentry.withScope(function (scope) {
      scope.setTag("wopo-error-slug", route.params.slug.toString());
      Sentry.captureMessage("Public examination summary without record");
    });
  }
  if (!record.value.userHasMembership) {
    await router.push({ name: "public-examination-payment", params: { slug: record.value.slug } });
  } else {
    try {
      await getAttemptInProgress({ slug: record.value.slug });
    } catch (error) {
      sendServerError(error);
    }
  }
});

function continueAttempt() {
  router.push({ name: "in-attempt", params: { uuid: inProgress.value.uuid } });
}
</script>

<template>
  <no-header-view>
    <h2 class="text-3xl font-semibold text-secondary-800">{{ title }}</h2>
    <div class="my-6 inline flex min-h-[3.2rem] justify-between align-middle">
      <p class="pt-3 text-lg font-light">Aquí encontrarás tu historial de test realizados con estadísticas.</p>
      <button-secondary v-show="showContinueButton" @click.prevent="continueAttempt" class="flex transition-opacity">
        <span>Retoma tu último test</span>
        <arrow-path-icon class="ml-2 mt-0.5 h-5" />
      </button-secondary>
    </div>
    <router-view v-slot="{ Component }">
      <fade-transition>
        <component :is="Component" />
      </fade-transition>
    </router-view>
  </no-header-view>
</template>
