<script setup></script>

<template>
  <div class="flex flex-row justify-center">
    <div class="mx-10 my-20">
      <h1 class="mb-10 text-5xl">Esta página no existe</h1>
      <p class="text-lg">
        Volver a
        <router-link class="underline" :to="{ name: 'home' }">Oposiciones</router-link>
      </p>
    </div>
  </div>
</template>

<style scoped></style>
