<script setup>
import TableWithFilters from "@/components/tables/TableWithFilters.vue";
import { useManageUsersStore } from "@/stores/ManageUsersStore.js";
import { computed, inject, onActivated, onMounted, ref } from "vue";
import { storeToRefs } from "pinia";
import { buildKeyAndArrayFormatter, yesNoFormatter } from "@/components/utils.js";
import StaffRepository from "@/repositories/StaffRepository.js";
import { useToast } from "@/components/composables/notifications.js";
import BaseInput from "@/components/forms/BaseInput.vue";
import BaseLabel from "@/components/forms/BaseLabel.vue";
import { debounce } from "lodash";
import BaseSelect from "@/components/forms/BaseSelect.vue";
import BaseComplexSelect from "@/components/forms/BaseComplexSelect.vue";
import { usePagination } from "@/components/composables/ws-pagination.js";
import { EyeIcon } from "@heroicons/vue/24/outline";
import { useRouter } from "vue-router";
import ButtonSecondary from "@/components/buttons/ButtonSecondary.vue";

const store = useManageUsersStore();
const router = useRouter();

const user = inject("user");
const { sendServerError } = useToast();

const table = ref(null);
const { academy, showUser, userType, query, userStatus, initialPageSize, initialOffset } = storeToRefs(store);
const { refreshFromLocal, storeToLocal } = store;

const { items: academies, getList: getAcademies } = usePagination(StaffRepository.academyList, 100, 0);

const showUserOptions = [
  { label: "Registrados", value: "true" },
  { label: "Invitados", value: "false" },
];
const userTypeOptions = [
  { label: "Alumnos", value: "0" },
  { label: "Staff", value: "1" },
];
const statusOptions = [
  { label: "Activos", value: "true" },
  { label: "Inactivos", value: "false" },
];

const fields = computed(() => {
  return [
    { label: "Apellido", key: "user", hide: showUser.value === "false", formatter: (user) => user.lastName },
    { label: "Nombre", key: "user", hide: showUser.value === "false", formatter: (user) => user.firstName },
    // { label: "Email", key: "user", hide: showUser.value === "false", formatter: (user) => user.email },
    {
      label: "Activo",
      key: "user",
      hide: showUser.value === "false",
      formatter: (user) => yesNoFormatter(user.isActive),
    },
    { label: "Academia", key: "academy", hide: user.value && !user.value.isSuperuser && !user.value.isStaff },
    {
      label: "Tipo usuario",
      key: "user",
      hide: showUser.value === "false",
      formatter: (user) => (user.isSuperuser || user.isStaff || user.isAcademyStaff ? "Staff" : "Alumno"),
    },
    { label: "Email notificación", key: "onboardingEmail", hide: showUser.value !== "false" },
    {
      label: "Oposiciones",
      key: "publicExaminations",
      formatter: buildKeyAndArrayFormatter("shortName"),
    },
    { label: "Acción", key: "actions" },
  ];
});

onMounted(async () => {
  await refreshFromLocal();
  if (table.value) {
    table.value.setPageSizeAndOffset(parseInt(initialPageSize.value), parseInt(initialOffset.value));
  }
  try {
    await getAcademies({});
    await refreshData();
  } catch (e) {
    sendServerError(e, "IN-INIT");
  }
});

onActivated(async () => {
  try {
    await refreshData();
  } catch (e) {
    sendServerError(e);
  }
});

function buildParams() {
  if (showUser.value === "false") {
    return {
      academy: academy.value != null ? academy.value.id : null,
      onboardingStatus: showUser.value,
      query: query.value,
    };
  }
  return {
    academy: academy.value != null ? academy.value.id : null,
    isActive: showUser.value !== "false" ? userStatus.value : null,
    userType: userType.value,
    query: query.value,
    onboardingStatus: showUser.value,
  };
}

async function refreshTable() {
  try {
    await storeToLocal();
    await table.value.refreshTable(buildParams());
  } catch (e) {
    sendServerError(e, "USR-LIST");
  }
}

async function refreshData() {
  try {
    await table.value.refreshData(buildParams());
  } catch (e) {
    sendServerError(e, "USR-LIST");
  }
}

function storeOffset(data) {
  initialOffset.value = data.offset;
  initialPageSize.value = data.pageSize;
  storeToLocal();
}

const search = debounce((newValue, oldValue) => {
  query.value = newValue.target.value;
  refreshTable();
}, 500);

function editUser(row) {
  router.push({ name: "edit-user", params: { id: row.id } });
}

function goToCreateUser() {
  router.push({ name: "create-user" });
}

function goToInviteUsers() {
  router.push({ name: "invite-user" });
}
</script>

<template>
  <div>
    <table-with-filters
      ref="table"
      title="Gestión de usuarios"
      :fields="fields"
      :call="StaffRepository.userList"
      :initial-offset="initialOffset"
      :initial-page-size="initialPageSize"
      :skip-initial-refresh="true"
      @offset-changed="storeOffset"
    >
      <template v-slot:filters>
        <div class="flex w-full flex-col">
          <div class="my-2 flex flex-row-reverse">
            <div class="ml-4 flex-shrink-0">
              <base-label label="Buscar" for-label="input-reason" />
              <base-input
                class="w-[40rem] border-secondary-200 bg-background text-secondary-600"
                v-model="query"
                id="input-query"
                name="input-query"
                @input="search"
              />
            </div>
          </div>
          <div class="flex flex-row-reverse">
            <div class="ml-4 flex-shrink-0">
              <base-label label="Filtro" for-label="select-filter" />
              <base-select
                v-model="showUser"
                id="select-filter"
                name="select-filter"
                :options="showUserOptions"
                @change="refreshTable"
              />
            </div>
            <div class="ml-4 flex-shrink-0">
              <base-label label="Tipo de usuario" for-label="select-user-type" />
              <base-select
                v-model="userType"
                id="select-user-type"
                name="select-user-type"
                :options="userTypeOptions"
                @change="refreshTable"
              />
            </div>
            <div class="ml-4 flex-shrink-0">
              <base-label label="Estado de usuario" for-label="select-status" />
              <base-select
                v-model="userStatus"
                id="select-status"
                name="select-status"
                :options="statusOptions"
                @change="refreshTable"
              />
            </div>
            <div class="ml-4 min-w-[10rem]">
              <base-label label="Academia" for-label="select-academy" />
              <base-complex-select
                v-model="academy"
                id="select-academy"
                name="select-academy"
                :value-option="(item) => item.id"
                :label-option="(item) => item.name"
                :options="academies"
                show-blank-option
                @change="refreshTable"
              >
                <template v-slot:blankOption> Todas</template>
              </base-complex-select>
            </div>
          </div>
        </div>
      </template>
      <template #cell(actions)="{ row }">
        <div class="flex flex-row">
          <button class="rounded p-1.5 hover:bg-primary-100" @click="editUser(row)" title="Detalle usuario">
            <EyeIcon class="block h-5 w-5" />
          </button>
        </div>
      </template>
      <template v-slot:table-footer>
        <div class="mb-8 mt-4 text-center">
          <button-secondary class="mr-4" @click.prevent="goToCreateUser">Crear usuario</button-secondary>
          <button-secondary @click.prevent="goToInviteUsers">Invitar usuario</button-secondary>
        </div>
      </template>
    </table-with-filters>
  </div>
</template>
