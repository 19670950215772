<script setup>
import { ref, watchEffect } from "vue";

const props = defineProps({
  iconName: {
    type: String,
    required: true,
  },
  title: {
    type: String,
    default: "svg",
  },
  alt: {
    type: String,
    default: "svg",
  },
});

const icon = ref(null);

watchEffect(async () => {
  icon.value = (await import(`../assets/icons/${props.iconName}.svg`)).default;
});
</script>

<template>
  <img :src="icon" :title="title" :alt="alt" />
</template>
