<script setup>
import ALink from "@/components/buttons/ALink.vue";

const adminLawUrl = import.meta.env.VITE_BACKEND_URL + "/wopo-admin/wopoedition/law/";
</script>

<template>
  <div class="mx-5">
    <p class="mb-2 text-lg text-red-200">
      Alguna pregunta tiene alguna ley sin sincronizar. <br />
      Para sincronizar las preguntas siga estos pasos:
    </p>
    <ol class="mx-5 list-decimal">
      <li class="mb-2">
        Diríjase al
        <a-link :href="adminLawUrl">panel de administración > Leyes</a-link>
      </li>
      <li class="mb-2">
        Se podrá filtrar las leyes (en el panel situado a la derecha) por aquellas que no están sincronizadas
      </li>
      <li class="mb-2">
        Seleccionar las leyes que se quieren sincronizar (en el checkbox que aparece a la izquierda de cada una de las
        leyes)
        <img class="h-50" src="@/assets/tutorials/laws/sincronizar_ley_1.png" alt="Sincronizar ley paso 1" />
      </li>
      <li class="mb-2">
        En "Acción" seleccionar "Sincronizar de edición a contenido"
        <img class="h-50" src="@/assets/tutorials/laws/sincronizar_ley_2.png" alt="Sincronizar ley paso 2" />
      </li>
      <li class="mb-2">
        Hacer clic en Ir
        <img class="h-50" src="@/assets/tutorials/laws/sincronizar_ley_3.png" alt="Sincronizar ley paso 3" />
      </li>
      <li class="mb-2"><slot></slot></li>
    </ol>
  </div>
</template>
