<script setup></script>

<template>
  <div>
    <h2 class="mb-3 flex items-center text-3xl font-bold">Flujo de publicación de exámenes</h2>
    <hr class="mb-3" />
    <div class="text-left">
      <img class="mb-10 h-auto" src="@/assets/exam-flow.png" alt="Flujo de publicación de examenes" />
      <div class="mb-3">
        <dt class="text-xl font-bold">Examen no publicado</dt>
        <dd>
          El examen no ha sido publicado anteriormente, puede estar listo para publicar (todas sus preguntas están
          revisadas) o tener pendiente de revisar algunas preguntas.
        </dd>
      </div>

      <div class="mb-3">
        <dt class="text-xl font-bold">Examen publicado</dt>
        <dd>El examen tiene todas sus preguntas revisadas y ha sido publicado.</dd>
      </div>

      <div class="mb-3">
        <dt class="text-xl font-bold">Examen despublicado</dt>
        <dd>
          El examen ha sido publicado anteriormente y se ha marcado como despublicado. Este examen
          <em class="font-bold not-italic">NO</em> será visible para los alumnos en la plataforma.
        </dd>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
