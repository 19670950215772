<template>
  <div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="22.988"
      height="22.985"
      viewBox="0 0 22.988 22.985"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectángulo_386"
            data-name="Rectángulo 386"
            width="22.988"
            height="22.985"
            transform="translate(0 0)"
            fill="none"
          />
        </clipPath>
      </defs>
      <g id="Grupo_294" data-name="Grupo 294" transform="translate(0 0)">
        <g id="Grupo_293" data-name="Grupo 293" clip-path="url(#clip-path)">
          <path
            id="Trazado_7545"
            data-name="Trazado 7545"
            d="M14.451.39A11.491,11.491,0,1,0,8.537,22.6a11.3,11.3,0,0,0,2.945.387A11.491,11.491,0,0,0,14.451.39"
            fill="#c4d600"
          />
          <path
            id="Trazado_7546"
            data-name="Trazado 7546"
            d="M11.218,14.6H11.21a.879.879,0,0,1-.871-.887v-.439c0-.025,0-.051,0-.076A4.206,4.206,0,0,1,11.991,10.5c.231-.214.45-.415.636-.626a1.19,1.19,0,0,0,.21-1.414A1.941,1.941,0,0,0,10.7,7.7,1.635,1.635,0,0,0,9.518,8.944a.88.88,0,1,1-1.7-.455,3.333,3.333,0,0,1,2.492-2.5,3.655,3.655,0,0,1,4.066,1.634,2.934,2.934,0,0,1-.437,3.423,10.342,10.342,0,0,1-.759.751c-.63.582-1.024.971-1.091,1.533v.4a.878.878,0,0,1-.871.867"
            fill="#fff"
          />
          <path
            id="Trazado_7547"
            data-name="Trazado 7547"
            d="M11.218,17.231a.885.885,0,0,1-.619-.255.875.875,0,0,1-.006-1.237l.006-.006a.909.909,0,0,1,1.239,0,.876.876,0,0,1-.6,1.5h-.018"
            fill="#fff"
          />
        </g>
      </g>
    </svg>
  </div>
</template>
