<script setup>
import { useWS } from "@/components/composables/ws-call.js";
import StaffRepository from "@/repositories/StaffRepository.js";
import { computed, onMounted, ref } from "vue";
import { useToast } from "@/components/composables/notifications.js";
import BaseLabel from "@/components/forms/BaseLabel.vue";
import TableWithFilters from "@/components/tables/TableWithFilters.vue";
import { TrashIcon } from "@heroicons/vue/24/outline";
import ButtonSecondary from "@/components/buttons/ButtonSecondary.vue";
import BaseDialog from "@/components/BaseDialog.vue";
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";
import { usePagination } from "@/components/composables/ws-pagination.js";
import BaseComplexSelect from "@/components/forms/BaseComplexSelect.vue";
import { yesNoFormatter } from "@/components/utils.js";

const props = defineProps({
  wopoUserId: {
    type: [Number, String],
    required: true,
  },
});

const { sendServerError, sendWarnNotification, sendNotification } = useToast();

const tableAcademy = ref(null);
const isDialogOpen = ref(false);
const selectedPublicExamination = ref(null);

const { callWs: addNewSubscription } = useWS(StaffRepository.newAcademySubscription());
const { callWs: deleteSubscription } = useWS(StaffRepository.deleteAcademySubscription());
const { items: publicExaminations, getList } = usePagination(StaffRepository.publicExaminationList, 100, 0);

const academySubscriptionsFields = computed(() => {
  return [
    { label: "Oposición", key: "publicExamination", formatter: (item) => item.shortName },
    { label: "Tiene acceso", key: "hasAccess", formatter: yesNoFormatter },
    { label: "Acciones", key: "actions" },
  ];
});

onMounted(async () => {
  try {
    await refreshData();
    await getList({});
  } catch (e) {
    sendServerError(e, "IN-INIT");
  }
});

function buildParams() {
  return {
    wopocampususer: props.wopoUserId,
  };
}

async function refreshData() {
  try {
    await tableAcademy.value.refreshData(buildParams());
  } catch (e) {
    sendServerError(e, "USR-LIST");
  }
}

async function deleteAcademySubscription(row) {
  try {
    await deleteSubscription(row.id);
  } catch (error) {
    sendServerError(error);
  }

  refreshData();
  sendNotification(null, "Éxito", "Suscripción borrada correctamente");
}

async function createSubscription() {
  if (selectedPublicExamination.value != null) {
    try {
      await addNewSubscription({
        wopocampususer: props.wopoUserId,
        publicExamination: selectedPublicExamination.value.id,
        hasAccess: true,
      });
      refreshData();
      sendNotification(null, "Éxito", "Suscripción creada correctamente");
      isDialogOpen.value = false;
    } catch (e) {
      sendServerError(e);
    }
  } else {
    sendWarnNotification("Seleccione una oposición", "Por favor, seleccione una oposición válida");
  }
}
</script>
<template>
  <div>
    <table-with-filters
      ref="tableAcademy"
      title="Suscripciones de academia"
      :fields="academySubscriptionsFields"
      :call="StaffRepository.academySubscriptionList"
      :skip-initial-refresh="true"
      class="mt-8"
    >
      <template #cell(actions)="{ row }">
        <div class="flex flex-row">
          <button
            @click="deleteAcademySubscription(row)"
            class="rounded p-1.5 hover:bg-primary-100"
            title="Borrar suscripción"
          >
            <TrashIcon class="block h-5 w-5" />
          </button>
        </div>
      </template>

      <template v-slot:table-footer>
        <div class="text-center">
          <button-secondary @click="isDialogOpen = true">Añadir suscripción</button-secondary>
        </div>
      </template>
    </table-with-filters>

    <base-dialog :is-open="isDialogOpen">
      <template v-slot:header> Añadir Suscripción</template>
      <template v-slot:default>
        <div class="flex flex-col">
          <div>Seleccione una oposición</div>
          <div class="ml-4 min-w-[10rem]">
            <base-label label="Oposición" for-label="select-public-examination" />
            <base-complex-select
              v-model="selectedPublicExamination"
              id="select-public-examination"
              name="select-public-examination"
              :value-option="(item) => item.id"
              :label-option="(item) => item.shortName"
              :options="publicExaminations"
              show-blank-option
            >
              <template v-slot:blankOption> Seleccione oposición</template>
            </base-complex-select>
          </div>
        </div>
      </template>
      <template v-slot:buttons>
        <button-primary class="mr-2" @click="createSubscription()">Añadir</button-primary>
        <button-secondary @click="isDialogOpen = false">Cancelar</button-secondary>
      </template>
    </base-dialog>
  </div>
</template>
