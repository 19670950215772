<script setup>
import BasePayment from "@/components/payments/BasePayment.vue";
import { inject } from "vue";
import NoHeaderView from "@/layout-components/NoHeaderView.vue";

const publicExaminations = inject("publicExaminations");
</script>

<template>
  <no-header-view>
    <base-payment class="mt-10" :public-examinations="publicExaminations"></base-payment>
  </no-header-view>
</template>
