<script setup>
import { Disclosure, DisclosureButton, DisclosurePanel, Menu, MenuButton, MenuItem, MenuItems } from "@headlessui/vue";
import {
  Bars3Icon,
  ChevronDownIcon,
  HandRaisedIcon,
  UserCircleIcon,
  XMarkIcon,
  BellIcon,
} from "@heroicons/vue/24/outline";
import { computed, inject, onBeforeUnmount, onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import MainRepository from "@/repositories/MainRepository.js";
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";
import { useVersioning } from "@/components/composables/versioning.js";
import { useToast } from "@/components/composables/notifications.js";

defineProps({
  publicExaminations: {
    type: Array,
    default: () => [],
  },
});
const user = inject("user");
const route = useRoute();
const { sendServerError } = useToast();
const { isQA } = useVersioning();

const fullName = computed(() => {
  if (user.value == null) return "";
  return user.value.firstName + " " + user.value.lastName;
});

const doTestLink = computed(() => {
  return { name: "Hacer test", href: { name: "home" }, current: route.name === "home" };
});

const navigation = computed(() => {
  const list = [
    {
      name: "Oposiciones",
      href: { name: "home" },
      current: route.name === "home",
    },
    {
      name: "Estadísticas",
      href: { name: "public-examination-user-statistics-no-slug" },
      current: route.name === "public-examination-user-statistics-no-slug",
    },
  ];
  if (user.value != null && (user.value.isSuperuser || user.value.isStaff || user.value.isAcademyStaff)) {
    list.push({
      name: "Usuarios",
      href: {
        name: "manage-users",
      },
    });
    list.push({
      name: "Estadísticas generales",
      href: {
        name: "staff-public-examination-statistics",
      },
    });
  }
  return list;
});

const mobileNavigation = computed(() => {
  const list = [...navigation.value];
  list.push(doTestLink.value);
  return list;
});

const closeSession = async () => {
  await MainRepository.logout();
  window.location = import.meta.env.VITE_BACKEND_URL;
};

const hasNotification = ref(false);
const fetchNotifications = async () => {
  try {
    const response = await MainRepository.getPendingNotifications();
    hasNotification.value = response.data.hasPendingNotifications;
  } catch (e) {
    sendServerError(e, "ALERT-NOTIFY");
  }
};

let notificationInterval;
onMounted(() => {
  fetchNotifications();
  notificationInterval = setInterval(fetchNotifications, 300000); // 5min
});

onBeforeUnmount(() => {
  clearInterval(notificationInterval);
});

const adminUrl = import.meta.env.VITE_BACKEND_URL + "/wopo-admin";
</script>

<template>
  <Disclosure
    as="nav"
    :class="{
      'bg-orange-700': isQA,
      'bg-secondary-800': !isQA,
    }"
    v-slot="{ open }"
  >
    <div class="px-4 sm:px-6 lg:px-8">
      <div class="flex h-24 items-center justify-between">
        <div class="flex items-center">
          <div class="flex-shrink-0">
            <a href="https://ruthgalvan.es" target="_blank">
              <img class="h-10" src="@/assets/logo-ruth-galvan-horizontal-blanco.svg" alt="Academia Ruth Galván logo" />
            </a>
          </div>
          <h1 v-if="isQA" class="ml-5 text-3xl font-semibold text-white">QA</h1>
          <div class="hidden md:block">
            <div class="ml-16 flex items-baseline space-x-4">
              <router-link
                v-for="item in navigation"
                :key="item.name"
                :to="item.href"
                active-class="text-white"
                :class="[
                  'text-white hover:bg-secondary-600 hover:text-secondary-50',
                  'text-md rounded-md px-3 py-2 font-normal leading-6 tracking-normal',
                ]"
                :aria-current="item.current ? 'page' : undefined"
                >{{ item.name }}
              </router-link>
              <a
                v-if="user != null && user.isStaff"
                :href="adminUrl"
                :class="[
                  'text-white hover:bg-secondary-600 hover:text-secondary-50',
                  'text-md rounded-md px-3 py-2 font-normal leading-6 tracking-normal',
                ]"
              >
                Panel administración
              </a>
            </div>
          </div>
        </div>
        <div class="hidden md:block">
          <div class="ml-4 flex items-center md:ml-6">
            <!-- Profile dropdown -->
            <div>
              <button-primary class="mr-2" @click="$router.push(doTestLink.href)">{{ doTestLink.name }}</button-primary>
            </div>
            <router-link class="relative ml-3" :to="{ name: 'notification-list' }">
              <a
                title="Abrir notificaciones"
                class="relative flex max-w-xs items-center rounded-full text-white focus:outline-none"
              >
                <BellIcon class="h-8" aria-hidden="true" />
                <span
                  v-if="hasNotification"
                  class="absolute right-0 top-0 block h-2 w-2 -translate-y-1/2 translate-x-1/2 transform rounded-full bg-red-500 ring-2 ring-white"
                />
              </a>
            </router-link>
            <Menu as="div" class="relative ml-4">
              <div>
                <MenuButton
                  title="Abrir menu contacto"
                  class="flex max-w-xs items-center rounded-full text-white focus:outline-none"
                >
                  <HandRaisedIcon class="h-8" aria-hidden="true" />
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <menu-items
                  class="absolute right-0 z-40 mt-2 w-48 origin-top-right divide-y divide-blue rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <menu-item
                    disabled
                    as="p"
                    class="block cursor-pointer px-4 py-2 text-sm font-bold text-secondary-800 hover:bg-primary-400"
                  >
                    Soporte
                  </menu-item>
                  <menu-item
                    disabled
                    class="block cursor-pointer px-4 py-2 text-sm text-secondary-800 hover:bg-primary-400"
                  >
                    <a href="mailto:soporte@ruthgalvan.es">soporte@ruthgalvan.es</a>
                  </menu-item>
                  <menu-item
                    disabled
                    as="p"
                    class="block cursor-pointer px-4 py-2 text-sm text-secondary-800 hover:bg-primary-400"
                  >
                    676 18 11 93 - Daniel
                  </menu-item>
                  <menu-item
                    disabled
                    as="p"
                    class="block cursor-pointer px-4 py-2 text-sm text-secondary-800 hover:bg-primary-400"
                  >
                    674 40 86 20 - Maribel
                  </menu-item>
                </menu-items>
              </transition>
            </Menu>
            <Menu as="div" class="relative ml-3">
              <div>
                <MenuButton class="flex max-w-xs items-center rounded-full text-white focus:outline-none">
                  <span class="sr-only">Abrir menu usuario</span>
                  <UserCircleIcon class="h-14" aria-hidden="true" />
                  <div class="flex min-w-[8rem] flex-col items-start px-3">
                    <span class="text-md font-medium">{{ fullName }}</span>
                    <div class="mt-1.5 flex flex-row text-sm">
                      Mi cuenta
                      <ChevronDownIcon class="ml-1 mt-1 h-4 font-bold text-white" />
                    </div>
                  </div>
                </MenuButton>
              </div>
              <transition
                enter-active-class="transition ease-out duration-100"
                enter-from-class="transform opacity-0 scale-95"
                enter-to-class="transform opacity-100 scale-100"
                leave-active-class="transition ease-in duration-75"
                leave-from-class="transform opacity-100 scale-100"
                leave-to-class="transform opacity-0 scale-95"
              >
                <menu-items
                  class="absolute right-0 z-40 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none"
                >
                  <menu-item
                    as="p"
                    class="block cursor-pointer px-4 py-2 text-sm text-secondary-800 hover:bg-primary-400"
                    @click="closeSession"
                  >
                    Cerrar sesión
                  </menu-item>
                </menu-items>
              </transition>
            </Menu>
          </div>
        </div>
        <div class="-mr-2 flex md:hidden">
          <!-- Mobile menu button -->
          <DisclosureButton
            class="inline-flex items-center justify-center rounded-md bg-secondary-800 p-2 text-secondary-100 hover:bg-secondary-600 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-secondary-800"
          >
            <span class="sr-only">Open main menu</span>
            <Bars3Icon v-if="!open" class="block h-6 w-6" aria-hidden="true" />
            <XMarkIcon v-else class="block h-6 w-6" aria-hidden="true" />
          </DisclosureButton>
        </div>
      </div>
    </div>

    <DisclosurePanel class="md:hidden">
      <div class="space-y-1 px-2 pb-3 pt-2 sm:px-3">
        <DisclosureButton
          v-for="item in mobileNavigation"
          :key="item.name"
          @click="$router.push(item.href)"
          :class="[
            item.current ? 'bg-gray-900 text-white' : 'text-secondary-200 hover:bg-gray-700 hover:text-white',
            'block rounded-md px-3 py-2 text-base font-medium',
          ]"
          :aria-current="item.current ? 'page' : undefined"
        >
          {{ item.name }}
        </DisclosureButton>
      </div>
      <div class="border-t border-gray-700 pb-3 pt-4 text-secondary-200">
        <div class="mb-4 flex items-center px-5">
          <div class="flex-shrink-0">
            <UserCircleIcon class="h-8" aria-hidden="true" />
          </div>
          <div class="ml-3">
            <div class="text-base font-medium leading-none text-white">{{ fullName }}</div>
          </div>
        </div>
        <div class="flex items-center px-5 font-semibold">Soporte</div>
        <div class="flex items-center px-5">
          <a href="mailto:soporte@ruthgalvan.es">soporte@ruthgalvan.es</a>
        </div>
        <div class="flex items-center px-5">676 18 11 93 - Daniel</div>
        <div class="flex items-center px-5">674 40 86 20 - Maribel</div>
        <div class="mt-3 space-y-1 px-2">
          <DisclosureButton
            @click="closeSession"
            class="block rounded-md px-3 py-2 text-base font-medium text-gray-400 hover:bg-gray-700 hover:text-white"
          >
            Cerrar sesión
          </DisclosureButton>
        </div>
      </div>
    </DisclosurePanel>
  </Disclosure>
</template>
