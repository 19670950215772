<script setup></script>

<template>
  <div>
    <h2 class="mb-3 flex items-center text-3xl font-bold">Flujo de revisión de preguntas</h2>
    <hr class="mb-3" />
    <div class="text-left">
      <img class="mb-10 h-auto" src="@/assets/question-flow.svg" alt="Fujo de estados de pregunta" />
      <div class="mb-3">
        <dt class="text-xl font-bold">Pregunta no revisada</dt>
        <dd>
          La pregunta no ha sido revisada, se ha guardado sin revisar o se ha marcado como pregunta no revisada. Esta
          pregunta no será publicada en la plataforma hasta que esté revisada. Existe un filtro en el listado de
          preguntas que permitirá ver solo aquellas preguntas no revisadas.
        </dd>
      </div>

      <div class="mb-3">
        <dt class="text-xl font-bold">Pregunta Válida</dt>
        <dd>La pregunta ha sido revisada y marcada como válida. Esta pregunta se publicará en la plataforma.</dd>
      </div>

      <div class="mb-3">
        <dt class="text-xl font-bold">Pregunta Descartada</dt>
        <dd>
          La pregunta ha sido revisada y marcada como descartada. Esta pregunta se considera no válida para la
          platforma. Esta pregunta <em class="font-bold not-italic">NO</em> se publicará en la plataforma.
        </dd>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
