<script setup>
import { Dialog, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from "@headlessui/vue";
import ButtonTertiary from "@/components/buttons/ButtonTertiary.vue";
import { ref, watch } from "vue";
import { useWindowScroll } from "@vueuse/core";

const props = defineProps({
  isOpen: {
    type: Boolean,
    default: false,
  },
});

defineEmits(["close-dialog"]);

// There is a bug around headless transition or dialog related to https://github.com/tailwindlabs/headlessui/issues/2522
// this make that click on pause will scroll to top, after remove pause the users loose the context
// So we save and restore manually scroll position
const { y } = useWindowScroll();
const lastYScroll = ref(0);
watch(
  () => props.isOpen,
  (newVisible) => {
    if (newVisible) {
      lastYScroll.value = y.value;
      console.log(`storing latest value ${y.value}, window ${window.scrollY}`);
    } else {
      console.log(`current ${window.scrollY}, latest ${lastYScroll.value}`);
      // without the timout scrollToTop does not work, I guess making the change in a watcher make things not working
      setTimeout(() => {
        window.scrollTo({ top: lastYScroll.value, left: window.scrollX, behavior: "instant" });
      }, 100);
    }
  }
);
</script>

<template>
  <TransitionRoot appear :show="isOpen" as="template">
    <Dialog as="div" @close="$emit('close-dialog')" class="relative z-50">
      <TransitionChild
        as="template"
        enter="duration-300 ease-out"
        enter-from="opacity-0"
        enter-to="opacity-100"
        leave="duration-200 ease-in"
        leave-from="opacity-100"
        leave-to="opacity-0"
      >
        <div class="fixed inset-0 bg-secondary-100/90 backdrop-blur-sm" />
      </TransitionChild>

      <div class="fixed inset-0 overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center">
          <TransitionChild
            as="template"
            enter="duration-300 ease-out"
            enter-from="opacity-0 scale-95"
            enter-to="opacity-100 scale-100"
            leave="duration-200 ease-in"
            leave-from="opacity-100 scale-100"
            leave-to="opacity-0 scale-95"
          >
            <DialogPanel
              class="w-full max-w-md transform overflow-hidden rounded-2xl bg-white p-6 text-left align-middle shadow-xl transition-all"
            >
              <DialogTitle as="h3" class="text-lg font-medium leading-6 text-gray-900">
                <slot name="title">Intento pausado</slot>
              </DialogTitle>
              <slot></slot>
              <div class="mt-4">
                <button-tertiary @click="$emit('close-dialog')">
                  <slot name="buttonText">Reanudar</slot>
                </button-tertiary>
              </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>
