<script setup>
import { computed, onActivated, onMounted, ref } from "vue";
import { DocumentDuplicateIcon, EyeIcon, PencilSquareIcon, PlusCircleIcon, TrashIcon } from "@heroicons/vue/24/outline";
import { usePagination } from "@/components/composables/ws-pagination.js";
import MainRepository from "@/repositories/MainRepository.js";
import { buildKeyAndArrayFormatter, dateFromISO, yesNoCapitalizedFormatter } from "@/components/utils.js";
import BaseSelect from "@/components/forms/BaseSelect.vue";
import BaseLabel from "@/components/forms/BaseLabel.vue";
import BaseComplexSelect from "@/components/forms/BaseComplexSelect.vue";
import { useRouter } from "vue-router";
import TableWithFilters from "@/components/tables/TableWithFilters.vue";
import { useToast } from "@/components/composables/notifications.js";
import TitleHeaderView from "@/layout-components/TitleHeaderView.vue";
import { useQuestionEditionStore } from "@/stores/QuestionEdtitionStore.js";
import { storeToRefs } from "pinia";
import BaseInput from "@/components/forms/BaseInput.vue";
import { debounce } from "lodash";
import BaseCard from "@/components/BaseCard.vue";
import ButtonSecondary from "@/components/buttons/ButtonSecondary.vue";
import { useWS } from "@/components/composables/ws-call.js";
import BaseDialog from "@/components/BaseDialog.vue";
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";
import QuestionListLawMappingError from "@/components/imported-questions/QuestionListLawMappingError.vue";

const router = useRouter();
const { sendServerError } = useToast();

const props = defineProps({
  showTitle: {
    type: Boolean,
    default: true,
  },
  examId: {
    type: [Number, null],
    default: null,
  },
  oppositionIds: {
    type: Array,
    default: () => [],
  },
  showOpposition: {
    type: Boolean,
    default: true,
  },
  showHeader: {
    type: Boolean,
    default: true,
  },
  skipClasses: {
    type: Boolean,
    default: false,
  },
  isExam: {
    type: Boolean,
    default: false,
  },
});

const store = useQuestionEditionStore();
const { sendNotification } = useToast();
const fields = computed(() => {
  if (props.isExam) {
    return [
      {
        label: "Oposiciones",
        key: "oppositionNames",
        hide: !props.showOpposition,
        formatter: (value) => value.join(", "),
      },
      { label: "Pregunta", key: "declaration" },
      { label: "Revisada", key: "isReviewed", formatter: alternativeReviewStateFormatter },
      { label: "Leyes", key: "lawsData", formatter: buildKeyAndArrayFormatter("law", "shortName") },
      { label: "Acción", key: "actions" },
    ];
  } else {
    return [
      {
        label: "Oposiciones",
        key: "oppositionNames",
        hide: !props.showOpposition,
        formatter: (value) => value.join(", "),
      },
      { label: "Pregunta", key: "declaration" },
      { label: "Revisada", key: "isReviewed", formatter: alternativeReviewStateFormatter },
      { label: "Leyes", key: "lawsData", formatter: buildKeyAndArrayFormatter("law", "shortName") },
      { label: "Acción", key: "actions" },
    ];
  }
});

function alternativeReviewStateFormatter(value, row) {
  let result = yesNoCapitalizedFormatter(value);
  if (row["changedSincePublication"]) {
    result += "*";
  }
  return result;
}

const subTypeOptions = [
  { label: "Todas", value: null },
  { label: "Procesal", value: "procesal" },
  { label: "General", value: "general" },
  { label: "Ninguno", value: "without" },
];

const reviewOptions = [
  { label: "No revisada", value: "0" },
  { label: "Revisada-válida", value: "1" },
  { label: "Revisada-descartada", value: "2" },
  { label: "Todas", value: "3" },
];

const table = ref(null);
const oppositionForMark = ref(null);
const oppositionForSync = ref(null);
const showContentLevelSelect = ref(false);
const selectedContentLevels = ref([]);
const contentLevelForMarkRecord = ref(null);
const markSubmit = ref(true);
const syncing = ref(false);
const pendingForSync = ref(null);
const noSynced = ref(0);
const syncMessage = ref("");
const markExam = ref(false);
const { opposition, questionSubtype, reviewed, contentLevel, law, initialPageSize, initialOffset, reason } =
  storeToRefs(store);
const { refreshFromLocal, storeToLocal } = store;

const exam = ref(props.examId);

const { items: oppositions, getList: getOppositions } = usePagination(MainRepository.oppositionList, 100, 0);
const { items: laws, getList: getLaws } = usePagination(MainRepository.lawList, 100, 0);
const {
  record: contentLevelRecord,
  items: contentLevels,
  getList: getContentLevels,
} = usePagination(MainRepository.contentLevelList, 100, 0);

const { items: contentLevelsForMark, getList: getContentLevelsForMark } = usePagination(
  MainRepository.contentLevelList,
  100,
  0
);
const { callWs: syncQuestionsWS, record: syncResult } = useWS(MainRepository.syncQuestions());
const title = computed(() => {
  if (props.isExam) return "Preguntas del Examen";
  if (props.showTitle) return "Revisión: banco de preguntas";
  return null;
});

const computedLaws = computed(() => {
  if (laws.value == null) return [];
  return [{ id: -1, shortName: "Sin asignar" }].concat(laws.value);
});

onMounted(async () => {
  // Recuperate data from store
  await refreshFromLocal(exam.value != null ? exam.value : "0");

  table.value.setPageSizeAndOffset(parseInt(initialPageSize.value), parseInt(initialOffset.value));
  try {
    await getLaws({});
    await getOppositions({
      with_questions: true,
    });
    for (let i = 0; i < props.oppositionIds.length; i++) {
      await getContentLevels({
        with_questions: true,
        opposition: props.oppositionIds[i],
      });
    }
    if (opposition.value !== null) {
      await getContentLevels({
        with_questions: true,
        opposition: opposition.value.id,
      });
    }
    await refreshData();
  } catch (e) {
    sendServerError(e, "IN-INIT");
  }
});

function afterRefreshData() {
  if (!table.value.items) {
    return;
  }
  for (let i = 0; i < table.value.items.length; i++) {
    let item = table.value.items[i];
    if (item.changedSincePublication) {
      item.helpColumn = "isReviewed";
    }
  }
}

onActivated(async () => {
  try {
    await refreshData();
  } catch (error) {
    sendServerError(error);
  }
});

function buildParams() {
  let params = {
    without_exams: exam.value == null ? true : null,
    reviewed: reviewed.value,
    laws: law.value != null ? law.value.id : null,
    opposition: opposition.value != null ? opposition.value.id : null,
    content_level_id: contentLevel.value != null ? contentLevel.value.id : null,
    exams: exam.value != null ? [exam.value] : null,
    reason: reason.value != null ? reason.value : null,
  };
  if (!props.isExam && questionSubtype.value !== null) {
    params.subtype = questionSubtype.value;
  }
  return params;
}

async function oppositionChanged() {
  contentLevel.value = null;
  if (opposition.value != null) {
    try {
      getContentLevels({
        with_questions: true,
        opposition: opposition.value.id,
      });
    } catch (e) {
      sendServerError(e, "CONT-LVL-LIST");
    }
  } else {
    contentLevel.value = null;
    contentLevelRecord.value = null;
  }
  await refreshTable();
}

async function oppositionChangedForMark() {
  selectedContentLevels.value = [];
  markSubmit.value = true;
  if (oppositionForMark.value != null) {
    try {
      getContentLevelsForMark({
        with_questions: true,
        opposition: oppositionForMark.value.id,
      });
    } catch (e) {
      sendServerError(e, "CONT-LVL-LIST");
    }
  } else {
    contentLevelForMarkRecord.value = null;
  }
}

async function refreshTable() {
  if (!table.value) {
    return;
  }
  try {
    await storeToLocal(exam.value != null ? exam.value : "0");
    await table.value.refreshTable(buildParams());
    afterRefreshData();
  } catch (e) {
    sendServerError(e, "QST-LIST");
  }
}

async function refreshData() {
  if (!table.value) {
    return;
  }
  try {
    await table.value.refreshData(buildParams());
    afterRefreshData();
  } catch (e) {
    sendServerError(e, "QST-LIST");
  }
}

function needsChecking(row) {
  if (row.lastReviewed == null || dateFromISO(row.lastImportAt) > dateFromISO(row.lastReviewed)) {
    return "sí";
  }
  return "no";
}

function isValid(row) {
  if (!row.isAnnulled && !row.isRepealed) {
    return "sí";
  }
  return "no";
}

function storeOffset(data) {
  initialOffset.value = data.offset;
  initialPageSize.value = data.pageSize;
  storeToLocal(exam.value != null ? exam.value : "0");
}

const search = debounce((newValue, oldValue) => {
  reason.value = newValue.target.value;
  refreshTable();
}, 500);

function addContentLevel() {
  if (contentLevelForMarkRecord.value != null) {
    selectedContentLevels.value.push(contentLevelForMarkRecord.value);
    contentLevelForMarkRecord.value = null;
    showContentLevelSelect.value = false;
    markSubmit.value = false;
  }
}

function removeContent(id) {
  let index = selectedContentLevels.value.map((item) => item.id).indexOf(id);

  if (index !== -1) {
    selectedContentLevels.value.splice(index, 1);
  }
  if (selectedContentLevels.value.length === 0) {
    markSubmit.value = true;
  }
}

async function markContentLevels() {
  if (selectedContentLevels.value.length > 0) {
    await MainRepository.markEditionContentLevelAsNoReviewed(selectedContentLevels.value.map((item) => item.id));
    markSubmit.value = true;
    selectedContentLevels.value = [];
    sendNotification(null, "Éxito", "Preguntas marcadas como no revisadas");
  }
}

async function markExamQuestions() {
  if (exam.value != null) {
    markExam.value = true;
    await MainRepository.markEditionExamQuestionsAsNoReviewed(exam.value);
    markExam.value = false;
    sendNotification(null, "Éxito", "Preguntas marcadas como no revisadas");
    refreshTable();
  }
}

let timeout = null;
const lawMappingError = ref(false);

async function syncQuestions() {
  lawMappingError.value = false;

  clearTimeout(timeout);
  if (oppositionForSync.value != null) {
    syncing.value = true;
    let onSync = true;
    syncMessage.value = "Sincronizando";
    while (onSync) {
      try {
        await syncQuestionsWS({ opposition: oppositionForSync.value.id });
      } catch (error) {
        sendServerError(error, "SYNC");
        onSync = false;
        syncMessage.value = "Fin de sincronización";
        timeout = setTimeout(() => {
          syncing.value = false;
        }, 5000);
        if (error.response?.data?.code === "law_mapping_does_not_exist") {
          lawMappingError.value = true;
        }
        break; // if an error happened stop the synchronization
      }
      if (syncResult.value != null) {
        noSynced.value += syncResult.value.done;
        pendingForSync.value = syncResult.value.pending;
        if (pendingForSync.value === 0) {
          onSync = false;
          timeout = setTimeout(() => {
            syncing.value = false;
          }, 5000);
          syncMessage.value = "Fin de sincronización";
        }
      } else {
        onSync = false;
      }
    }
  } else {
    sendNotification("warn", "Seleccione oposición", "Seleccione una oposición para realizar la sincronización");
  }
}

function resetSync() {
  syncing.value = false;
  pendingForSync.value = null;
}

const isHelpReviewDialogOpen = ref(false);

function help({ column }) {
  if (column.key === "isReviewed") {
    isHelpReviewDialogOpen.value = true;
  }
}

const adminLawUrl = import.meta.env.VITE_BACKEND_URL + "/wopo-admin/wopoedition/law/";
</script>

<template>
  <title-header-view :show-header="showHeader" :skip-classes="skipClasses">
    <template v-slot:header>Edición</template>
    <table-with-filters
      ref="table"
      :title="title"
      :fields="fields"
      :call="MainRepository.questionList"
      :initial-offset="initialOffset"
      :initial-page-size="initialPageSize"
      :skip-initial-refresh="true"
      :show-loading-at-start="true"
      @offset-changed="storeOffset"
      @help="help"
    >
      <template v-slot:filters>
        <div class="flex flex-col">
          <div class="my-2 flex flex-row-reverse">
            <div class="ml-4 flex-shrink-0">
              <base-label label="Buscar por pregunta, respuesta o explicación" for-label="input-reason" />
              <base-input
                class="border-secondary-200 bg-background text-secondary-600"
                v-model="reason"
                id="input-reason"
                name="input-reason"
                @input="search"
              />
            </div>
          </div>

          <div class="flex flex-row">
            <div v-if="!isExam" class="ml-4 flex-shrink-0">
              <base-label label="Subtipo" for-label="subtype" />
              <base-select
                v-model="questionSubtype"
                id="subtype"
                name="subtype"
                :options="subTypeOptions"
                @change="refreshTable"
              />
            </div>
            <div class="ml-4 flex-shrink-0">
              <base-label label="Estado de la pregunta" for-label="select-review" />
              <base-select
                v-model="reviewed"
                id="select-review"
                name="select-review"
                :options="reviewOptions"
                @change="refreshTable"
              />
            </div>
            <div class="ml-4 min-w-[10rem]">
              <base-label label="Tema" for-label="select-content-level" />
              <base-complex-select
                v-model="contentLevel"
                id="select-content-level"
                name="select-content-level"
                :value-option="(item) => item.id"
                :label-option="(item) => item.name"
                :options="contentLevels"
                show-blank-option
                @change="refreshTable"
              >
                <template v-slot:blankOption> Todos</template>
              </base-complex-select>
            </div>
            <div class="ml-4 min-w-[9rem] max-w-[20rem]">
              <base-label label="Ley" for-label="select-law" />
              <base-complex-select
                v-model="law"
                id="select-laws"
                name="select-laws"
                :value-option="(item) => item.id"
                :label-option="(item) => item.shortName"
                :options="computedLaws"
                show-blank-option
                @change="refreshTable"
              >
                <template v-slot:blankOption> Todas</template>
              </base-complex-select>
            </div>
            <div v-if="exam == null" class="ml-4 min-w-[14rem]">
              <base-label label="Oposición" for-label="select-opposition" />
              <base-complex-select
                v-model="opposition"
                id="select-opposition"
                name="select-opposition"
                :value-option="(item) => item.id"
                :label-option="(item) => item.name"
                :options="oppositions"
                show-blank-option
                @change="oppositionChanged"
              >
                <template v-slot:blankOption> Todas</template>
              </base-complex-select>
            </div>
          </div>
        </div>
      </template>
      <template #cell(check)="{ row }">
        {{ needsChecking(row) }}
      </template>
      <template #cell(valid)="{ row }">
        {{ isValid(row) }}
      </template>
      <template #cell(actions)="{ row }">
        <div class="flex flex-row">
          <router-link
            class="rounded p-1.5 hover:bg-primary-100"
            title="editar"
            :to="{ name: 'question-detail', params: { id: row.id } }"
          >
            <PencilSquareIcon class="block h-5 w-5" />
          </router-link>
          <router-link
            class="rounded p-1.5 hover:bg-primary-100"
            title="duplicar"
            :to="{ name: 'duplicate-question', params: { id: row.id } }"
          >
            <DocumentDuplicateIcon class="block h-5 w-5" />
          </router-link>
          <router-link
            class="rounded p-1.5 hover:bg-primary-100"
            title="vista previa"
            :to="{ name: 'question-preview', params: { id: row.id } }"
          >
            <EyeIcon class="block h-5 w-5" />
          </router-link>
        </div>
      </template>
    </table-with-filters>
    <base-card v-if="exam == null">
      <h3 class="mb-4 ml-4 text-lg">Marcar como no revisadas</h3>
      <div class="flex flex-col">
        <div class="ml-4 min-w-[14rem]">
          <base-label label="Selección oposición" for-label="select-opposition-mark" />
          <base-complex-select
            v-model="oppositionForMark"
            id="select-opposition-mark"
            name="select-opposition-mark"
            :value-option="(item) => item.id"
            :label-option="(item) => item.name"
            :options="oppositions"
            show-blank-option
            @change="oppositionChangedForMark"
          >
            <template v-slot:blankOption>---</template>
          </base-complex-select>
        </div>
        <div class="my-4 ml-4">
          <base-label label="Seleccione temas" />
          <ul class="grid grid-cols-2 gap-2 text-center align-middle">
            <li
              class="relative mr-2 mt-4 w-full border border-2 border-secondary-100 p-2"
              v-for="description in selectedContentLevels"
              :key="description.id"
            >
              {{ description.name }}
              <button
                class="absolute right-2 hover:bg-primary-100"
                @click.prevent="removeContent(description.id)"
                title="eliminar"
              >
                <trash-icon class="block h-5 w-5" />
              </button>
            </li>
            <li class="mt-4 w-full">
              <button
                v-show="!showContentLevelSelect"
                class="mt-2.5 flex flex-row hover:bg-primary-100"
                @click.prevent="showContentLevelSelect = true"
                title="eliminar"
              >
                Anadir
                <plus-circle-icon class="ml-2 mt-1 block h-5 w-5" />
              </button>
              <base-complex-select
                v-if="showContentLevelSelect"
                v-model="contentLevelForMarkRecord"
                id="select-content-level"
                name="select-content-level"
                :value-option="(item) => item.id"
                :label-option="(item) => item.name"
                :options="contentLevelsForMark"
                show-blank-option
                @change="addContentLevel()"
              />
            </li>
          </ul>
        </div>
        <div>
          <button-secondary :disabled="markSubmit" class="mb-4 ml-4 !py-1" @click="markContentLevels"
            >Guardar
          </button-secondary>
        </div>
      </div>
    </base-card>

    <base-card v-if="exam != null">
      <h3 class="mb-4 ml-4 text-lg">Marcar las preguntas de este examen como no revisadas</h3>
      <div class="flex flex-col">
        <div class="my-4 ml-4">
          <button-secondary class="mb-4 ml-4 !py-1" :disable="markExam" @click="markExamQuestions">
            Marcar como no revisadas
          </button-secondary>
        </div>
      </div>
    </base-card>

    <base-card v-if="exam == null">
      <h3 class="mb-4 ml-4 text-lg">Sincronización de preguntas</h3>
      <div class="flex flex-col">
        <div class="ml-4 min-w-[14rem]">
          <base-label label="Selección oposición" for-label="select-opposition-sync" />
          <base-complex-select
            v-model="oppositionForSync"
            id="select-opposition-sync"
            name="select-opposition-sync"
            :value-option="(item) => item.id"
            :label-option="(item) => item.name"
            :options="oppositions"
            show-blank-option
            @change="resetSync"
          >
            <template v-slot:blankOption>Seleccione oposición</template>
          </base-complex-select>
        </div>
        <div>
          <button-secondary :disabled="syncing" class="my-4 ml-4 !py-1" @click="syncQuestions">
            <span v-if="!syncing">Sincronizar</span>
            <span v-else>Sincronizando...</span>
          </button-secondary>
        </div>
        <div class="ml-4" v-if="syncing">
          {{ syncMessage }} <b>{{ oppositionForSync.shortName }}</b>
        </div>
        <div class="ml-4" v-if="syncing">
          Pendientes: <span v-if="pendingForSync != null">{{ pendingForSync }}</span> <span v-else>??</span>.
          Sincronizadas: {{ noSynced }}
        </div>
        <question-list-law-mapping-error v-if="lawMappingError">
          En este momento podrá volver a sincronizar desde el banco de preguntas
        </question-list-law-mapping-error>
      </div>
    </base-card>

    <base-dialog :is-open="isHelpReviewDialogOpen">
      <template v-slot:default>
        <p>
          Esta pregunta está marcada con un asterisco "*" porque tiene cambios con respecto a la pregunta que está
          publicada <span v-if="isExam">en el examen</span> para los alumnos.
        </p>
      </template>
      <template v-slot:buttons>
        <button-primary @click="isHelpReviewDialogOpen = false">Entendido</button-primary>
      </template>
    </base-dialog>
  </title-header-view>
</template>
