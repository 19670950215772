import { defineStore } from "pinia";
import { ref } from "vue";

export const useExamEditionStore = defineStore("exam-edition", () => {
  const opposition = ref(null);
  const reviewed = ref("0");
  const published = ref("0");
  const initialPageSize = ref(10);
  const initialOffset = ref(0);
  const textSearch = ref("");

  function reset() {
    opposition.value = null;
    reviewed.value = null;
    published.value = null;
    initialPageSize.value = 10;
    initialOffset.value = 0;
    textSearch.value = "";
  }

  function refreshFromLocal(examListType) {
    let data = localStorage.getItem("exams_for_" + examListType);
    if (data != null) {
      data = JSON.parse(data);

      opposition.value = data.opposition;
      reviewed.value = data.reviewed;
      published.value = data.published;
      initialPageSize.value = parseInt(data.initialPageSize);
      initialOffset.value = parseInt(data.initialOffset);
      textSearch.value = data.textSearch || "";
    } else {
      reset();
    }
  }

  function storeToLocal(examListType) {
    localStorage.setItem(
      "exams_for_" + examListType,
      JSON.stringify({
        opposition: opposition.value,
        reviewed: reviewed.value,
        published: published.value,
        initialPageSize: initialPageSize.value,
        initialOffset: initialOffset.value,
        textSearch: textSearch.value,
      })
    );
  }

  return {
    opposition,
    reviewed,
    published,
    initialPageSize,
    initialOffset,
    textSearch,
    refreshFromLocal,
    storeToLocal,
  };
});
