import { defineStore } from "pinia";
import { ref } from "vue";

export const useManageUsersStore = defineStore("manage-users", () => {
  const STORE_KEY = "users";
  const academy = ref(null);
  const userType = ref("0");
  const query = ref(null);
  const userStatus = ref("true");
  const showUser = ref("true");
  const initialPageSize = ref(10);
  const initialOffset = ref(0);

  function getDatumFromLocal(data, datum, defaultValue = null) {
    if (data[datum] !== undefined) {
      return data[datum];
    }
    reset();
    storeToLocal();
    return defaultValue;
  }

  function reset() {
    academy.value = null;
    query.value = null;
    userType.value = "0";
    showUser.value = "true";
    userStatus.value = "true";
    initialPageSize.value = 10;
    initialOffset.value = 0;
  }

  function refreshFromLocal() {
    let data = localStorage.getItem(STORE_KEY);
    if (data != null) {
      data = JSON.parse(data);

      academy.value = getDatumFromLocal(data, "academy");
      query.value = getDatumFromLocal(data, "query");
      userType.value = getDatumFromLocal(data, "userType", "0");
      showUser.value = getDatumFromLocal(data, "showUser", "true");
      userStatus.value = getDatumFromLocal(data, "userStatus", "true");
      initialPageSize.value = parseInt(data.initialPageSize);
      initialOffset.value = parseInt(data.initialOffset);
    } else {
      reset();
    }
  }

  function storeToLocal() {
    localStorage.setItem(
      STORE_KEY,
      JSON.stringify({
        academy: academy.value,
        query: query.value,
        userType: userType.value,
        showUser: showUser.value,
        userStatus: userStatus.value,
        initialPageSize: initialPageSize.value,
        initialOffset: initialOffset.value,
      })
    );
  }

  return {
    academy,
    showUser,
    userType,
    query,
    userStatus,
    initialPageSize,
    initialOffset,
    refreshFromLocal,
    storeToLocal,
  };
});
