<template>
  <div class="animate-pulse border-t border-secondary-200">
    <dl>
      <div class="bg-secondary-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-secondary-600">Titulo</dt>
        <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
          <div class="w-4/4 mx-3 my-10 h-3 rounded bg-slate-200"></div>
        </dd>
      </div>
      <div v-show="false" class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-secondary-600">Enunciado</dt>
        <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
          <div class="w-4/4 mx-3 my-10 h-3 rounded bg-slate-200"></div>
        </dd>
      </div>
    </dl>
  </div>
</template>
