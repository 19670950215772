<script setup></script>

<template>
  <transition mode="out-in">
    <slot></slot>
  </transition>
</template>

<style scoped>
.v-enter-active {
  transition: all 0.3s cubic-bezier(0.34, 1.56, 0.64, 1);
}

.v-leave-active {
  transition: all 0.3s cubic-bezier(0.36, 0, 0.66, -0.56);
}

.v-enter-from,
.v-leave-to {
  translate: 4rem;
  transform: rotate(130deg);
}
</style>
