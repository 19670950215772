<template>
  <div class="animate-pulse border-t border-secondary-200">
    <dl>
      <div class="bg-secondary-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-secondary-600">Oposición</dt>
        <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
          <div class="w-4/4 mx-3 my-10 h-3 rounded bg-slate-200"></div>
        </dd>
      </div>
      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-secondary-600">Temas</dt>
        <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
          <div class="fw-full lex mt-1">
            <div class="mx-3 my-10 h-3 w-3/4 rounded bg-slate-200"></div>
          </div>
        </dd>
      </div>
      <div class="bg-secondary-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-secondary-600">Exámenes</dt>
        <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
          <div class="w-4/4 mx-3 my-10 h-3 rounded bg-slate-200"></div>
        </dd>
      </div>
      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-secondary-600">Leyes</dt>
        <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
          <div class="fw-full lex mt-1">
            <div class="mx-3 my-10 h-3 w-3/4 rounded bg-slate-200"></div>
          </div>
        </dd>
      </div>
      <div class="bg-secondary-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-secondary-600">Dificultad</dt>
        <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
          <div class="w-4/4 mx-3 my-10 h-3 rounded bg-slate-200"></div>
        </dd>
      </div>
      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-secondary-600">Pregunta</dt>
        <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
          <div class="fw-full lex mt-1">
            <div class="mx-3 my-10 h-3 w-3/4 rounded bg-slate-200"></div>
          </div>
        </dd>
      </div>

      <div class="bg-secondary-50 px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-secondary-600">Respuestas</dt>
        <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
          <ul role="list" class="divide-y divide-secondary-100 rounded-md">
            <li class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
              <div class="w-full sm:col-span-2">
                <div class="fw-full lex mt-1">
                  <div class="mx-3 my-10 h-3 w-1/2 rounded bg-slate-200"></div>
                </div>
              </div>
            </li>
            <li class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
              <div class="w-full sm:col-span-2">
                <div class="fw-full lex mt-1">
                  <div class="mx-3 my-10 h-3 w-1/2 rounded bg-slate-200"></div>
                </div>
              </div>
            </li>
            <li class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
              <div class="w-full sm:col-span-2">
                <div class="fw-full lex mt-1">
                  <div class="mx-3 my-10 h-3 w-1/2 rounded bg-slate-200"></div>
                </div>
              </div>
            </li>
            <li class="flex items-center justify-between py-3 pl-3 pr-4 text-sm">
              <div class="w-full sm:col-span-2">
                <div class="fw-full lex mt-1">
                  <div class="mx-3 my-10 h-3 w-1/2 rounded bg-slate-200"></div>
                </div>
              </div>
            </li>
          </ul>
        </dd>
      </div>
      <div class="bg-white px-4 py-5 sm:grid sm:grid-cols-3 sm:gap-4 sm:px-6">
        <dt class="text-sm font-medium text-secondary-600">Explicación</dt>
        <dd class="my-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
          <div class="mx-3 my-10 h-3 w-3/4 rounded bg-slate-200"></div>
        </dd>
      </div>
    </dl>
  </div>
</template>
