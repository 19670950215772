<script setup>
import { computed, ref } from "vue";
import { PlusCircleIcon, TrashIcon } from "@heroicons/vue/24/outline/index.js";
import BaseComplexSelect from "@/components/forms/BaseComplexSelect.vue";

const props = defineProps({
  label: {
    type: String,
    required: true,
  },
  values: {
    type: Array,
    required: true,
  },
  valueLabel: {
    type: Function,
    required: true,
  },
  selectValues: {
    type: Array,
    required: true,
  },
  selectValueLabel: {
    type: Function,
    required: true,
  },
  selectValueValue: {
    type: Function,
    required: true,
  },
  emptyErrorLabel: {
    type: String,
    default: "",
  },
  bgColorClass: {
    type: String,
    default: "bg-white",
  },
});

const emit = defineEmits(["remove", "change"]);

const showSelect = ref(false);
const selectedValue = ref(null);

const availableValues = computed(() => {
  return props.selectValues.filter((value) => !props.values.map((v) => v.id).includes(value.id));
});

const changeValue = () => {
  emit("change", selectedValue.value);
  selectedValue.value = null;
  showSelect.value = false;
};
</script>

<template>
  <div class="py-5 sm:grid sm:grid-cols-3 sm:gap-4" :class="bgColorClass">
    <dt class="text-base font-medium text-secondary-600">{{ label }}</dt>
    <dd class="mt-1 text-sm text-secondary-800 sm:col-span-2 sm:mt-0">
      <ul class="flex flex-col">
        <li
          class="relative mt-4 w-full border border-2 border-secondary-100 p-2 first:mt-0"
          v-for="value in values"
          :key="value.id"
        >
          {{ valueLabel(value) }}
          <button class="absolute right-2 hover:bg-primary-200" @click.prevent="emit('remove', value)" title="Eliminar">
            <trash-icon class="block h-5 w-5" />
          </button>
        </li>
        <li class="mt-2 w-full first:mt-0">
          <button
            v-show="!showSelect"
            class="p-2 hover:bg-primary-200"
            @click.prevent="showSelect = true"
            title="Añadir"
          >
            <plus-circle-icon class="block h-5 w-5" />
          </button>
          <base-complex-select
            v-if="showSelect"
            v-model="selectedValue"
            id="select-law"
            name="select-law"
            :value-option="(law) => selectValueValue(law)"
            :label-option="(law) => selectValueLabel(law)"
            :options="availableValues"
            show-blank-option
            @change="changeValue"
          />
        </li>
      </ul>
    </dd>
    <p v-if="emptyErrorLabel && values.length <= 0" class="col-span-3 text-red-200">
      {{ emptyErrorLabel }}
    </p>
  </div>
</template>

<style scoped></style>
