<script setup>
import { computed } from "vue";

const props = defineProps({
  value: {
    type: [Number, null],
    required: true,
  },
  disabled: {
    type: Boolean,
    default: false,
  },
  active: {
    type: Boolean,
    default: false,
  },
});

const buttonClass = computed(() => {
  if (props.active) {
    return "bg-secondary-800 text-white";
  }
  return "";
});

const emit = defineEmits(["set-page"]);

function setPage() {
  emit("set-page", props.value);
}
</script>
<template>
  <button
    :class="buttonClass"
    class="mr-1 rounded-full border border-secondary-200 px-3 py-1 shadow hover:bg-secondary-600 disabled:bg-background disabled:text-secondary-400"
    @click="setPage"
    :disabled="disabled"
  >
    <slot></slot>
  </button>
</template>
