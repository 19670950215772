<template>
  <div class="animate-pulse overflow-hidden bg-white">
    <div class="mx-4 mt-3 h-3 rounded bg-slate-200"></div>
    <div class="mx-4 mt-3 h-3 w-3/4 rounded bg-slate-200"></div>
    <div class="mx-5 p-2">
      <div class="mx-3 my-10 h-3 w-1/4 rounded bg-slate-200"></div>
      <div class="mx-3 my-10 h-3 w-3/4 rounded bg-slate-200"></div>
      <div class="mx-3 my-10 h-3 w-2/4 rounded bg-slate-200"></div>
      <div class="mx-3 my-10 h-3 w-1/4 rounded bg-slate-200"></div>
    </div>
  </div>
</template>
