<script setup>
import { useRoute } from "vue-router";

import TitleHeaderView from "@/layout-components/TitleHeaderView.vue";
import QuestionPreviewBody from "@/components/imported-questions/QuestionPreviewBody.vue";

const route = useRoute();
const questionId = parseInt(route.params.id);
</script>

<template>
  <title-header-view>
    <template v-slot:header>Vista Previa</template>
    <question-preview-body :question-id="questionId"></question-preview-body>
  </title-header-view>
</template>
