<script setup>
import BaseCard from "@/components/BaseCard.vue";
import ButtonLink from "@/components/buttons/ButtonLink.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import { Popover, PopoverButton, PopoverPanel } from "@headlessui/vue";

defineProps({
  publicExaminations: {
    type: Array,
    required: true,
  },
});

const emit = defineEmits(["select"]);

function selectPublicExamination(publicExamination) {
  emit("select", publicExamination);
}

function tags(publicExamination) {
  // some browsers not so old do not support hasOwn so use hasOwnProperty for compatibility
  if (publicExamination.extra == null || !publicExamination.extra.hasOwnProperty("tags")) {
    return [];
  }
  return publicExamination.extra.tags.map((t) => {
    return {
      label: t,
    };
  });
}
</script>

<template>
  <base-card class="w-full">
    <h3 class="mb-7 ml-5 mt-3 text-2xl font-medium">Elige tu oposición</h3>
    <div
      v-for="(publicExamination, index) in publicExaminations"
      :key="index"
      class="m-4 flex cursor-pointer flex-col rounded rounded-lg bg-background px-6 py-4 hover:bg-background-hover sm:flex-row sm:items-center sm:justify-between"
      @click.prevent="selectPublicExamination(publicExamination)"
    >
      <div class="flex">
        <div class="mr-4 flex h-14 w-14 shrink-0 items-center">
          <base-icon
            v-if="publicExamination.userHasMembership"
            class="p-0"
            icon-name="basculacv"
            :title="publicExamination.shortName"
            :alt="publicExamination.shortName"
          />
          <base-icon
            v-else
            class="p-0"
            icon-name="basculacr"
            :title="publicExamination.shortName"
            :alt="publicExamination.shortName"
          />
        </div>
        <div class="flex flex-col">
          <div class="mb-1 flex flex-col text-xl font-medium text-secondary-800 sm:flex-row sm:items-center">
            <span class="mb-2 sm:mb-0">{{ publicExamination.shortName }}</span>
            <div
              v-for="(tag, tagIndex) in tags(publicExamination)"
              :key="tagIndex"
              class="sm:mb- mb-2 ml-0 w-fit shrink-0 rounded-full bg-white px-3 py-1 align-middle text-xs uppercase tracking-wider sm:ml-5"
              :class="'Turno libre' === tag.label ? 'text-primary-900' : 'text-red-200'"
            >
              {{ tag.label }}
            </div>
          </div>
          <span class="text-md sm:mb- mb-2 font-light text-secondary-800">{{ publicExamination.name }}</span>
        </div>
      </div>
      <div class="ml-[4.5rem] flex sm:ml-0">
        <Popover class="relative">
          <!--          <PopoverButton-->
          <!--            class="flex shrink-0 justify-center whitespace-nowrap text-base font-light text-secondary-800 underline hover:text-secondary-600 focus:outline-none focus-visible:ring-2 focus-visible:ring-secondary-400 focus-visible:ring-opacity-75 disabled:bg-secondary-400"-->
          <!--          >-->
          <!--            <span class="mr-2 underline">Más info</span>-->
          <!--            <base-icon icon-name="info" class="mr-2 mt-1 h-4" title="Más info" alt="Más info" />-->
          <!--          </PopoverButton>-->
          <transition
            enter-active-class="transition duration-200 ease-out"
            enter-from-class="translate-y-1 opacity-0"
            enter-to-class="translate-y-0 opacity-100"
            leave-active-class="transition duration-150 ease-in"
            leave-from-class="translate-y-0 opacity-100"
            leave-to-class="translate-y-1 opacity-0"
          >
            <PopoverPanel class="absolute left-0 z-20 mt-3 w-[25rem] -translate-x-[18rem] transform px-4 sm:px-0">
              <div class="overflow-hidden rounded-lg shadow-lg ring-1 ring-secondary-800 ring-opacity-5">
                <div class="relative bg-white p-7" v-html="publicExamination.info"></div>
              </div>
            </PopoverPanel>
          </transition>
        </Popover>
      </div>
    </div>
  </base-card>
</template>
