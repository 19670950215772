<script setup>
import BaseCard from "@/components/BaseCard.vue";
import BaseLabel from "@/components/forms/BaseLabel.vue";
import BaseTable from "@/components/tables/BaseTable.vue";
import BasePagination from "@/components/buttons/BasePagination.vue";
import { usePagination } from "@/components/composables/ws-pagination.js";
import { pageSizeOptions } from "@/components/utils.js";
import BaseSelectSecondary from "@/components/forms/BaseSelectSecondary.vue";
import SimplePagination from "@/components/buttons/SimplePagination.vue";
import { watch } from "vue";
import { useToast } from "@/components/composables/notifications.js";

const props = defineProps({
  initialPageSize: {
    type: [String, Number],
    default: 10,
  },
  initialOffset: {
    type: [String, Number],
    default: 0,
  },
  /**
   * Check documentation of fields in BaseTable
   */
  fields: {
    type: Array,
    required: true,
  },
  title: {
    type: String,
    default: null,
  },
  call: {
    type: Function,
    required: true,
  },
  showExtraFilters: {
    type: Boolean,
    default: true,
  },
  useBasicPagination: {
    type: Boolean,
    default: false,
  },
  skipInitialRefresh: {
    type: Boolean,
    default: false,
  },
  showLoadingAtStart: {
    type: Boolean,
    default: false,
  },
});

const emit = defineEmits(["offset-changed", "page", "help"]);

const { sendServerError } = useToast();

const { items, totalResults, page, pageSize, offset, isLoading, getList, refreshList } = usePagination(
  props.call,
  parseInt(props.initialPageSize),
  parseInt(props.initialOffset),
  props.skipInitialRefresh,
  props.showLoadingAtStart
);

async function refreshTable(params = {}) {
  offset.value = 0;
  try {
    await getList(params);
  } catch (e) {
    sendServerError(e);
  }
}

async function refreshData(params = {}) {
  try {
    await getList(params);
  } catch (e) {
    sendServerError(e);
  }
}

async function pageChanged(page) {
  offset.value = pageSize.value * (page - 1);
  try {
    await refreshList();
    emit("page");
  } catch (e) {
    sendServerError(e);
  }
}

function setPageSizeAndOffset(newPageSize, newOffset) {
  pageSize.value = newPageSize;
  offset.value = newOffset;
}

watch(offset, async (newOffset, oldOffset) => {
  emit("offset-changed", { offset: newOffset, pageSize: pageSize.value });
});

watch(pageSize, async (newPageSize, oldPageSize) => {
  emit("offset-changed", { offset: offset.value, pageSize: newPageSize });
});

defineExpose({
  refreshTable,
  refreshData,
  setPageSizeAndOffset,
  items,
});
</script>

<template>
  <div>
    <base-card>
      <div class="mx-2 my-4 flex place-items-center justify-between">
        <h2 v-if="title != null" class="text-2xl font-semibold text-secondary-800">{{ title }}</h2>
      </div>
      <div v-show="showExtraFilters" class="my-4 flex flex-row-reverse gap-4">
        <slot name="filters"></slot>
      </div>
      <simple-pagination
        v-if="useBasicPagination"
        :page-size="pageSize"
        :page="page"
        :total-results="totalResults"
        :show-current="false"
        class="my-8"
        @page="pageChanged"
      />
      <base-pagination
        v-else
        :page-size="pageSize"
        :page="page"
        :total-results="totalResults"
        :show-current="false"
        class="mb-3"
        @page="pageChanged"
      />
      <div class="mx-2 mb-2 flex flex-row">
        <base-table :fields="fields" :items="items" :busy="isLoading" @help="emit('help', $event)">
          <template v-for="(_, name) in $slots" v-slot:[name]="slotData">
            <slot :name="name" v-bind="slotData" />
          </template>
        </base-table>
      </div>

      <simple-pagination
        v-if="useBasicPagination"
        :page-size="pageSize"
        :page="page"
        :total-results="totalResults"
        :show-current="false"
        class="my-8"
        @page="pageChanged"
      />
      <base-pagination
        v-else
        :page-size="pageSize"
        :page="page"
        :total-results="totalResults"
        :show-current="false"
        @page="pageChanged"
      />

      <div class="my-3 flex justify-end">
        <div class="mr-4 flex items-baseline">
          <base-label
            label="Por página"
            for-label="select-page-size"
            class="mr-2 mt-[1px] whitespace-nowrap !text-base"
          />
          <base-select-secondary
            v-model="pageSize"
            id="select-page-size"
            name="select-page-size"
            :options="pageSizeOptions"
            class="w-min"
          />
        </div>
      </div>
      <slot name="table-footer" />
    </base-card>
  </div>
</template>
