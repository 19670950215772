<script setup>
import { ATTEMPT_CUSTOM } from "@/components/utils.js";
import { computed } from "vue";
import { attemptTypeIconSelector } from "@/components/icons.js";
import BaseIcon from "@/components/BaseIcon.vue";

const props = defineProps({
  type: {
    type: String,
    default: ATTEMPT_CUSTOM,
  },
  circleColor: {
    type: String,
    default: "green",
  },
  description: {
    type: String,
    required: true,
  },
});
const emit = defineEmits(["select-type"]);
const selectIcon = computed(() => {
  return attemptTypeIconSelector(props.type);
});

function selectType() {
  emit("select-type", props.type);
}
</script>

<template>
  <div>
    <a @click.prevent="selectType" class="cursor-pointer">
      <div class="m-4 flex items-center rounded-lg bg-background px-5 py-4 hover:bg-background-hover">
        <div class="flex">
          <div class="mr-4 flex h-16 w-16 shrink-0 items-center">
            <div class="p-0">
              <base-icon :icon-name="selectIcon" :title="selectIcon" :alt="selectIcon" />
            </div>
          </div>
          <div class="my-auto flex flex-col">
            <span class="text-lg font-semibold text-secondary-800"><slot>Test personalizado</slot></span>
            <span class="text-base font-light text-secondary-800">
              {{ description }}
            </span>
          </div>
        </div>
      </div>
    </a>
  </div>
</template>
