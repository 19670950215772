<script setup></script>

<template>
  <button
    class="justify-center rounded-full bg-secondary-800 bg-opacity-70 px-4 py-2 text-sm font-medium text-white hover:bg-opacity-30 focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
  >
    <slot></slot>
  </button>
</template>

<style scoped></style>
