import { defineStore } from "pinia";
import { ref } from "vue";

export const useQuestionEditionStore = defineStore("edition", () => {
  const opposition = ref(null);
  const valid = ref(null);
  const questionSubtype = ref(null);
  const reviewed = ref("0");
  const contentLevel = ref(null);
  const law = ref(null);
  const initialPageSize = ref(10);
  const initialOffset = ref(0);
  const reason = ref(null);

  function $reset() {
    opposition.value = null;
    valid.value = null;
    questionSubtype.value = null;
    reviewed.value = "0";
    contentLevel.value = null;
    reason.value = null;
    law.value = null;
    initialPageSize.value = 10;
    initialOffset.value = 0;
  }

  function refreshFromLocal(questionListType) {
    let data = localStorage.getItem("questions_for_" + questionListType);
    if (data != null) {
      data = JSON.parse(data);

      opposition.value = data.opposition;
      valid.value = data.valid;
      questionSubtype.value = data.questionSubtype;
      reviewed.value = data.reviewed;
      contentLevel.value = data.contentLevel;
      law.value = data.law;
      initialPageSize.value = data.initialPageSize;
      initialOffset.value = data.initialOffset;
      reason.value = data.reason;
    } else {
      $reset();
    }
  }

  function storeToLocal(questionType) {
    localStorage.setItem(
      "questions_for_" + questionType,
      JSON.stringify({
        opposition: opposition.value,
        valid: valid.value,
        questionSubtype: questionSubtype.value,
        reviewed: reviewed.value,
        contentLevel: contentLevel.value,
        law: law.value,
        initialPageSize: initialPageSize.value,
        initialOffset: initialOffset.value,
        reason: reason.value,
      })
    );
  }

  return {
    opposition,
    valid,
    questionSubtype,
    reviewed,
    contentLevel,
    law,
    initialPageSize,
    initialOffset,
    reason,
    refreshFromLocal,
    storeToLocal,
  };
});
