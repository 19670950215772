<script setup>
import { computed, toRaw } from "vue";

const props = defineProps({
  modelValue: {
    type: Object,
    default: null,
  },
  id: {
    type: String,
    default: "select",
  },
  name: {
    type: String,
    default: "select",
  },
  options: {
    type: Array,
    required: true,
  },
  labelOption: {
    type: Function,
    required: true,
  },
  valueOption: {
    type: Function,
    required: true,
  },
  showBlankOption: {
    type: Boolean,
    default: false,
  },
  required: {
    type: Boolean,
    default: false,
  },
});
const emit = defineEmits(["update:modelValue"]);

const plainOptions = computed(() => {
  return toRaw(props.options);
});

function optionFromId(id) {
  let option = plainOptions.value.find((option) => option.id.toString() === id);
  if (option === undefined) {
    return null;
  }
  return option;
}
</script>

<template>
  <select
    :id="id"
    :name="name"
    :required="required"
    class="block w-full rounded-lg border border-secondary-200 bg-background p-2 text-sm text-secondary-600 focus:border-primary-600 focus:ring-primary-600"
    @change="emit('update:modelValue', optionFromId($event.target.value))"
  >
    <option v-if="showBlankOption" value="" :selected="modelValue === null">
      <slot name="blankOption">----</slot>
    </option>
    <option
      v-for="option in plainOptions"
      :key="option.id"
      :value="valueOption(option)"
      :selected="modelValue !== null && option.id === modelValue.id"
    >
      {{ labelOption(option) }}
    </option>
  </select>
</template>
