<script setup>
import { computed, onMounted, ref } from "vue";
import BaseLabel from "@/components/forms/BaseLabel.vue";
import BaseSelect from "@/components/forms/BaseSelect.vue";
import BaseComplexSelect from "@/components/forms/BaseComplexSelect.vue";
import { PencilSquareIcon } from "@heroicons/vue/24/outline";
import { usePagination } from "@/components/composables/ws-pagination.js";
import MainRepository from "@/repositories/MainRepository.js";
import { useRouter } from "vue-router";
import { EXAM_TITLES, yesNoCapitalizedFormatter } from "@/components/utils.js";
import TableWithFilters from "@/components/tables/TableWithFilters.vue";
import { useToast } from "@/components/composables/notifications.js";
import TitleHeaderView from "@/layout-components/TitleHeaderView.vue";
import { useExamEditionStore } from "@/stores/ExamEdtitionStore.js";
import { storeToRefs } from "pinia";
import ButtonSecondary from "@/components/buttons/ButtonSecondary.vue";
import BaseInput from "@/components/forms/BaseInput.vue";
import { EyeIcon, TrashIcon } from "@heroicons/vue/24/outline/index.js";
import { DESPUBLICADO, NO_PUBLICADO, PUBLICADO, publishedStateFormatter } from "@/components/exams.js";
import ButtonPrimary from "@/components/buttons/ButtonPrimary.vue";
import BaseDialog from "@/components/BaseDialog.vue";
import ExamFlow from "@/components/imported-questions/ExamFlow.vue";
import BaseIcon from "@/components/BaseIcon.vue";
import { DocumentDuplicateIcon } from "@heroicons/vue/24/outline/index.js";
import BaseLoading from "@/components/BaseLoading.vue";

const router = useRouter();
const { sendServerError } = useToast();
const props = defineProps({
  examType: {
    type: String,
    default: "previousCall",
  },
});

const fields = [
  { label: "Oposición", key: "oppositionNames", formatter: (value) => value.join(", ") },
  { label: "Título", key: "title" },
  { label: "Revisado", key: "reviewed", formatter: yesNoCapitalizedFormatter },
  { label: "Publicación", key: "publishedState", formatter: alternativePublishedStateFormatter },
  { label: "Preguntas válidas", key: "numberOfValidQuestions", formatter: formatOverTotalOfQuestions },
  { label: "Preguntas revisadas", key: "numberOfReviewedQuestions", formatter: formatOverTotalOfQuestions },
  { label: "Nº de intentos", key: "numberOfAttempts", formatter: formatNumberOfAttempts },
  { label: "Acción", key: "actions" },
];

function alternativePublishedStateFormatter(value, row) {
  let result = publishedStateFormatter(value);
  if (row["changedSincePublication"]) {
    result += "*";
  }
  return result;
}

function formatOverTotalOfQuestions(value, row) {
  return value + " / " + row["numberOfQuestions"];
}

function formatNumberOfAttempts(value, row) {
  if (isNotPublished(row)) {
    return "-";
  }
  return value;
}

const reviewOptions = [
  { label: "Todos", value: "3" },
  { label: "Sí", value: "1" },
  { label: "No", value: "0" },
];

const publishOptions = [
  { label: "Todos", value: null },
  { label: publishedStateFormatter(PUBLICADO), value: PUBLICADO },
  { label: publishedStateFormatter(NO_PUBLICADO), value: NO_PUBLICADO },
  { label: publishedStateFormatter(DESPUBLICADO), value: DESPUBLICADO },
];

const table = ref(null);
const isDocumentationOpen = ref(false);

const store = useExamEditionStore();
const { opposition, reviewed, published, initialPageSize, initialOffset, textSearch } = storeToRefs(store);
const { refreshFromLocal, storeToLocal } = store;

const title = computed(() => {
  return "Revisión: " + EXAM_TITLES[props.examType];
});

const { items: oppositions, getList: getOppositions } = usePagination(MainRepository.oppositionList, 100, 0);
// const { callWs: syncExamsWS, record: syncResult } = useWS(MainRepository.syncExams());

onMounted(async () => {
  try {
    await refreshFromLocal(props.examType);
    table.value.setPageSizeAndOffset(parseInt(initialPageSize.value), parseInt(initialOffset.value));
    await getOppositions({
      with_questions: true,
    });
    await refreshData();
  } catch (error) {
    sendServerError(error);
  }
});

function buildParams() {
  let params = {
    reviewed: reviewed.value,
    published: published.value,
    exam_type: props.examType,
    opposition: opposition.value != null ? opposition.value.id : null,
  };
  if (textSearch.value !== "") {
    params.title = textSearch.value;
  }
  return params;
}

const isCloneExecuting = ref(false);
const isCloneDialogOpen = ref(false);
const clonedExamId = ref(null);
const currentExamId = ref(null);
const examTitle = ref(null);
const examNumberOfQuestion = ref(0);
const examOppositionNames = ref(null);
const numberOfOppositions = ref(0);

function openCloneDialog(examId) {
  if (!table.value.items) {
    return;
  }
  for (let i = 0; i < table.value.items.length; i++) {
    if (table.value.items[i].id === examId) {
      currentExamId.value = examId;
      examTitle.value = table.value.items[i].title;
      examNumberOfQuestion.value = table.value.items[i].numberOfQuestions;
      examOppositionNames.value = table.value.items[i].oppositionNames.join(", ");
      numberOfOppositions.value = table.value.items[i].oppositionNames.length;
    }
  }
  isCloneDialogOpen.value = true;
  isCloneExecuting.value = false;
}

async function goToCloneExam(examId) {
  await router.push({ name: "exam-detail", params: { examId: examId } });
}

async function clone(examId) {
  if (!table.value.items) {
    return;
  }
  for (let i = 0; i < table.value.items.length; i++) {
    if (table.value.items[i].id === examId) {
      try {
        isCloneExecuting.value = true;
        clonedExamId.value = await MainRepository.cloneExam(table.value.items[i]);
        await goToCloneExam(clonedExamId.value.data.examId);
      } catch (e) {
        sendServerError(e, "QST-LIST");
      } finally {
        isCloneDialogOpen.value = false;
        // since the modal takes some time if you set isCloneExecuting to false here the user could
        // click the button, so the button is disabled until the dialog is opened again in openCloneDialog
      }
    }
  }
}

const isRemoveExecuting = ref(false);
const isRemoveDialogOpen = ref(false);
const examNumberOfAttempts = ref(0);
const examPublishedState = ref("not_published");

function openRemoveDialog(examId) {
  if (!table.value.items) {
    return;
  }
  for (let i = 0; i < table.value.items.length; i++) {
    if (table.value.items[i].id === examId) {
      currentExamId.value = examId;
      examTitle.value = table.value.items[i].title;
      examNumberOfQuestion.value = table.value.items[i].numberOfQuestions;
      examNumberOfAttempts.value = table.value.items[i].numberOfAttempts;
      examPublishedState.value = table.value.items[i].publishedState;
      examOppositionNames.value = table.value.items[i].oppositionNames.join(", ");
      numberOfOppositions.value = table.value.items[i].oppositionNames.length;

      isRemoveDialogOpen.value = true;
      isRemoveExecuting.value = false;
    }
  }
}

async function remove(examId) {
  if (!table.value.items) {
    return;
  }
  for (let i = 0; i < table.value.items.length; i++) {
    if (table.value.items[i].id === examId) {
      try {
        isRemoveExecuting.value = true;
        await MainRepository.removeExam(examId);
        await refreshTable();
      } catch (e) {
        sendServerError(e, "QST-LIST");
      } finally {
        isRemoveDialogOpen.value = false;
        // since the modal takes some time if you set isRemoveExecuting to false here the user could
        // click the button, so the button is disabled until the dialog is opened again in openRemoveDialog
      }
    }
  }
}

async function searchChanged(newValue) {
  textSearch.value = newValue;
  try {
    await storeToLocal(props.examType);
    await refreshTable();
  } catch (e) {
    sendServerError(e, "EXM-LIST");
  }
}

async function refreshTable() {
  try {
    await storeToLocal(props.examType);
    await table.value.refreshTable(buildParams());
    afterRefreshData();
  } catch (e) {
    sendServerError(e, "EXM-LIST");
  }
}

async function refreshData() {
  try {
    await table.value.refreshData(buildParams());
    afterRefreshData();
  } catch (e) {
    sendServerError(e, "EXM-LIST");
  }
}

function afterRefreshData() {
  if (!table.value.items) {
    return;
  }
  for (let i = 0; i < table.value.items.length; i++) {
    let item = table.value.items[i];
    if (isNotPublished(item)) {
      item.helpColumn = "numberOfAttempts";
    }
    if (item["changedSincePublication"]) {
      item.helpColumn = "publishedState";
    }
  }
}

function isNotPublished(row) {
  return row["publishedState"] === NO_PUBLICADO;
}

function storeOffset(data) {
  initialOffset.value = data.offset;
  initialPageSize.value = data.pageSize;
  storeToLocal(props.examType);
}

const isHelpAttemptsDialogOpen = ref(false);
const isHelpPublishDialogOpen = ref(false);

function help({ column }) {
  if (column.key === "numberOfAttempts") {
    isHelpAttemptsDialogOpen.value = true;
  } else if (column.key === "publishedState") {
    isHelpPublishDialogOpen.value = true;
  }
}
</script>

<template>
  <title-header-view>
    <template v-slot:header>Edición</template>
    <table-with-filters
      ref="table"
      :title="title"
      :fields="fields"
      :call="MainRepository.examList"
      :initial-offset="initialOffset"
      :initial-page-size="initialPageSize"
      :skip-initial-refresh="true"
      :show-loading-at-start="true"
      @offset-changed="storeOffset"
      @help="help"
      @page="afterRefreshData"
    >
      <template v-slot:filters>
        <base-icon
          title="Ayuda"
          icon-name="question"
          class="-ml-4 h-8 w-8 cursor-pointer self-end pb-1"
          alt="Ayuda"
          @click.prevent="isDocumentationOpen = true"
        />
        <div>
          <base-label label="Publicación" for-label="select-published" />
          <base-select
            v-model="published"
            id="select-published"
            name="select-published"
            :options="publishOptions"
            @change="refreshTable"
          />
        </div>
        <div>
          <base-label label="Revisado" for-label="select-review" />
          <base-select
            v-model="reviewed"
            id="select-review"
            name="select-review"
            :options="reviewOptions"
            @change="refreshTable"
          />
        </div>
        <div class="min-w-[15rem]">
          <base-label label="Oposición" for-label="select-opposition" />
          <base-complex-select
            v-model="opposition"
            id="select-opposition"
            name="select-opposition"
            :value-option="(item) => item.id"
            :label-option="(item) => item.name"
            :options="oppositions"
            show-blank-option
            @change="refreshTable"
          >
            <template v-slot:blankOption> Todas</template>
          </base-complex-select>
        </div>
        <div>
          <base-label label="Buscar por título" for-label="title" />
          <base-input id="title" name="title" :initial-value="textSearch" @debounced-input="searchChanged" />
        </div>
        <base-dialog :is-open="isDocumentationOpen">
          <template v-slot:default>
            <ExamFlow></ExamFlow>
          </template>
          <template v-slot:buttons>
            <button-primary @click="isDocumentationOpen = false">Entendido</button-primary>
          </template>
        </base-dialog>
      </template>
      <template #cell(actions)="{ row }">
        <div class="flex flex-row">
          <router-link
            :to="{ name: 'exam-detail', params: { examId: row.id } }"
            class="rounded p-1.5 hover:bg-primary-100"
            title="Editar"
          >
            <PencilSquareIcon class="block h-5 w-5" />
          </router-link>
          <button @click="openCloneDialog(row.id)" class="rounded p-1.5 hover:bg-primary-100" title="Duplicar">
            <DocumentDuplicateIcon class="block h-5 w-5" />
          </button>
          <router-link
            class="rounded p-1.5 hover:bg-primary-100"
            title="Vista previa"
            :to="{ name: 'exam-preview', params: { examId: row.id } }"
          >
            <EyeIcon class="block h-5 w-5" />
          </router-link>
          <button @click="openRemoveDialog(row.id)" class="rounded p-1.5 hover:bg-primary-100" title="Eliminar">
            <trash-icon class="block h-5 w-5" />
          </button>
        </div>
      </template>
    </table-with-filters>
    <div class="my-5 text-center">
      <button-secondary v-if="examType === 'alleged'" @click.prevent="router.push({ name: 'new-exam' })">
        Nuevo examen
      </button-secondary>
    </div>

    <base-dialog :is-open="isHelpAttemptsDialogOpen">
      <template v-slot:default>
        <p>
          Este exámen no está publicado por lo tanto no puede tener ningún intento, por eso se marca con el símbolo "-"
          en vez de mostrarse el número de intentos.
        </p>
      </template>
      <template v-slot:buttons>
        <button-primary @click="isHelpAttemptsDialogOpen = false">Entendido</button-primary>
      </template>
    </base-dialog>

    <base-dialog :is-open="isHelpPublishDialogOpen">
      <template v-slot:default>
        <p>
          Este exámen está marcado con un asterisco "*" porque tiene cambios con respecto al exámen que está publicado
          para los alumnos.
        </p>
      </template>
      <template v-slot:buttons>
        <button-primary @click="isHelpPublishDialogOpen = false">Entendido</button-primary>
      </template>
    </base-dialog>

    <base-dialog :is-open="isCloneDialogOpen" @close_modal="isCloneDialogOpen = false">
      <template v-slot:header>¿Está seguro de que quiere duplicar el examen?</template>
      <template v-slot:default>
        <p>
          Se va a duplicar el examen <b>{{ examTitle }}</b> con <b>{{ examNumberOfQuestion }}</b> preguntas en
          <span v-if="numberOfOppositions <= 1"> en la oposición </span>
          <span v-else> en las oposiciones </span><b>{{ examOppositionNames }}</b> <b>{{ examOppositionNames }}</b
          >.
        </p>
      </template>
      <template v-slot:buttons>
        <base-loading :invisible="!isCloneExecuting" :size="6"></base-loading>
        <button-primary class="mr-2" @click.prevent="clone(currentExamId)" :disabled="isCloneExecuting">
          <span v-if="!isCloneExecuting">Clonar</span>
          <span v-else>Clonando...</span>
        </button-primary>
        <button-secondary :disabled="isCloneExecuting" @click="isCloneDialogOpen = false">Cancelar</button-secondary>
      </template>
    </base-dialog>
    <base-dialog :is-open="isRemoveDialogOpen" @close_modal="isRemoveDialogOpen = false">
      <template v-slot:header>¿Está seguro de que quiere eliminar el examen?</template>
      <template v-slot:default>
        <p>En el caso de que este examen se encuentre publicado, primero se despublicará y luego se borrará.</p>
        <p>
          Se va a borrar el examen <b>{{ examTitle }}</b> con <b>{{ examNumberOfQuestion }}</b> preguntas en
          <span v-if="numberOfOppositions <= 1"> en la oposición </span>
          <span v-else> en las oposiciones </span><b>{{ examOppositionNames }}</b>
          <span v-if="examPublishedState !== 'not_published'">
            los alumnos han realizado <b>{{ examNumberOfAttempts }}</b> intentos.
          </span>
          <span v-if="examPublishedState === 'published'">
            <br />El examen <b>se despublicará</b> y luego se eliminará
          </span>
        </p>
      </template>
      <template v-slot:buttons>
        <base-loading :invisible="!isRemoveExecuting" :size="6"></base-loading>
        <button-primary class="mr-2" @click.prevent="remove(currentExamId)" :disabled="isRemoveExecuting">
          <span v-if="!isRemoveExecuting">Borrar</span>
          <span v-else>Borrando...</span>
        </button-primary>
        <button-secondary :disabled="isRemoveExecuting" @click="isRemoveDialogOpen = false">Cancelar</button-secondary>
      </template>
    </base-dialog>
  </title-header-view>
</template>
