<script setup>
import { computed } from "vue";

const props = defineProps({
  modelValue: {},
  id: {
    type: String,
    default: "radio",
  },
  name: {
    type: String,
    default: "radio",
  },
  label: {
    type: String,
    required: true,
  },
  inputValue: {
    required: true,
  },
  isValid: {
    type: Boolean,
    default: false,
  },
});

const currentClass = computed(() => {
  if (props.modelValue != null) {
    if (props.isValid) {
      return "transition-colors delay-200 ease-in-out border border-2 rounded-md border-green";
    }
    if (props.modelValue === props.inputValue) {
      return "transition-colors delay-200 ease-in-out  border border-2 rounded-md border-red";
    }
  }
  return "border border-2 border-white";
});

defineEmits(["update:modelValue"]);
</script>

<template>
  <div class="flex items-center p-3" :class="currentClass">
    <input
      :id="id"
      :name="name"
      :value="inputValue"
      :checked="modelValue === inputValue"
      @change="$emit('update:modelValue', inputValue)"
      type="radio"
      class="h-4 w-4 cursor-pointer border-gray-300 text-gray-600 focus:ring-gray-500"
    />
    <label :for="id" class="text-md ml-5 block cursor-pointer font-sans text-gray-500">
      {{ label }}
    </label>
  </div>
</template>

<style scoped></style>
