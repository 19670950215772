export function useAuthentication() {
  const redirectToLoginPage = function (extraMessage = null) {
    let params = new URLSearchParams({
      next: encodeURI(import.meta.env.VITE_FRONTEND_URL),
    });
    if (extraMessage !== null) {
      params.append("extramessage", extraMessage);
    }
    let url = import.meta.env.VITE_BACKEND_URL + `/cuenta/inicio-sesion/?${params.toString()}`;
    location.replace(url);
  };

  return {
    redirectToLoginPage,
  };
}
