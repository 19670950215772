<script setup>
import BaseCard from "@/components/BaseCard.vue";
import { computed } from "vue";
import ButtonTertiary from "@/components/buttons/ButtonTertiary.vue";

const publicExaminations = computed(() => [{}, {}, {}]);
</script>

<template>
  <div class="mb-3 grid animate-pulse grid-cols-3 items-center justify-center gap-4">
    <base-card
      v-for="(publicExamination, index) in publicExaminations"
      :key="index"
      class="w-full animate-pulse border-t border-secondary-200"
    >
      <div class="flex w-full flex-row items-center justify-center text-center">
        <div class="mx-3 h-3 w-3/4 rounded bg-slate-200"></div>
      </div>
      <div class="mt-2 flex w-full flex-row items-center justify-center text-center">
        <button-tertiary>Ver detalles </button-tertiary>
      </div>
    </base-card>
  </div>
</template>
