<template>
  <div>
    <svg
      id="Grupo_292"
      data-name="Grupo 292"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink"
      width="25.269"
      height="24.22"
      viewBox="0 0 25.269 24.22"
      title="hola"
    >
      <defs>
        <clipPath id="clip-path">
          <rect
            id="Rectángulo_385"
            data-name="Rectángulo 385"
            width="25.269"
            height="24.22"
            transform="translate(0 0)"
            fill="#c4d600"
          />
        </clipPath>
      </defs>
      <g id="Grupo_291" data-name="Grupo 291" clip-path="url(#clip-path)">
        <path
          id="Trazado_7544"
          data-name="Trazado 7544"
          d="M25.213,9.148h0l-.007-.021a1.951,1.951,0,0,0-1.7-1.21l-6.35-.923-2.83-5.741A1.962,1.962,0,0,0,12.623,0a1.954,1.954,0,0,0-1.675,1.239L8.109,6.993l-6.336.921A1.962,1.962,0,0,0,.056,9.147a1.945,1.945,0,0,0,.661,1.975l4.6,4.48-1.085,6.31a2.041,2.041,0,0,0,.363,1.755,1.57,1.57,0,0,0,1.2.553H5.82a2.462,2.462,0,0,0,1.133-.312l5.681-2.989L18.311,23.9a2.5,2.5,0,0,0,1.137.313h.03a1.576,1.576,0,0,0,1.2-.56,2.031,2.031,0,0,0,.36-1.736L19.955,15.6l4.585-4.47a1.953,1.953,0,0,0,.673-1.982"
          fill="#c4d600"
        />
      </g>
    </svg>
  </div>
</template>
