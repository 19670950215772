<script setup></script>

<template>
  <button
    class="rounded bg-red-800 px-4 py-1.5 font-medium text-white hover:bg-red-600 disabled:cursor-not-allowed disabled:bg-secondary-400"
  >
    <slot></slot>
  </button>
</template>

<style scoped></style>
