<script setup>
import BasePayment from "@/components/payments/BasePayment.vue";
import { inject } from "vue";
import NoHeaderView from "@/layout-components/NoHeaderView.vue";

defineProps({
  slug: {
    type: String,
    required: true,
  },
});
const publicExaminations = inject("publicExaminations");
</script>

<template>
  <no-header-view>
    <base-payment
      class="mt-10"
      :public-examinations="publicExaminations"
      :selected-public-examination="slug"
    ></base-payment>
  </no-header-view>
</template>
