<script setup>
import { computed } from "vue";

const props = defineProps({
  error: {
    type: [String, Array],
    default: "",
  },
});

const finalError = computed(() => {
  if (typeof props.error === "string") {
    return props.error;
  }
  if (Array.isArray(props.error)) {
    return props.error
      .map((e) => {
        let message = e;
        if (typeof e === "object") {
          message = e.message;
        }
        // remove trailing dot from django
        if (message.endsWith(".")) {
          return message.substring(0, message.length - 1);
        }
        return message;
      })
      .join(", ");
  }
  throw Error(`Type not supported for "${props.error}" (type: ${typeof props.error})`);
});
</script>

<template>
  <div>
    <p v-if="finalError !== ''" class="block text-red-200">
      <slot>{{ finalError }}</slot>
    </p>
  </div>
</template>

<style scoped></style>
