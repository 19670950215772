<script setup>
import { computed } from "vue";

const props = defineProps({
  record: {
    type: [Object, null],
    required: true,
  },
});

const questions = computed(() => {
  if (props.record == null) {
    return "-";
  }
  return props.record.questions.length;
});

const answeredQuestions = computed(() => {
  if (props.record == null) {
    return "-";
  }
  return props.record.questions.filter((q) => q.selectedAnswer != null).length;
});

const progress = computed(() => {
  if (props.record == null) {
    return "0%";
  }
  return `${(answeredQuestions.value * 100) / questions.value}%`;
});
</script>

<template>
  <header class="sticky bottom-0 left-0 right-0 z-20 bg-white shadow-md">
    <div class="mx-auto max-w-7xl px-4 py-4 sm:px-6 lg:px-8">
      <div class="flex flex-col">
        <div class="flex flex-row justify-between text-sm font-light text-secondary-800">
          <div class="mb-2">Progreso</div>
          <div>{{ answeredQuestions }} de {{ questions }}</div>
        </div>
        <div class="h-1 w-full rounded-full bg-background">
          <div class="h-1 rounded-full bg-primary-800 transition-all" :style="'width: ' + progress"></div>
        </div>
      </div>
    </div>
  </header>
</template>
