<script setup></script>

<template>
  <div>
    <p class="mb-1">
      <b>Republicar con cambios menores</b>: Un cambio menor supone una modificación en algún campo del examen o las
      pregunta asociadas. En concreto:
    </p>
    <ul class="ml-5 list-disc">
      <li class="my-1 mb-1">Modificar el título, enunciado, categoría o orden del examen</li>
      <li class="my-1 mb-1">
        Modificar el texto de la pregunta o cualquiera de sus respuestas (si se cambia el orden de las respuestas o la
        respuesta correcta NO se considera un cambio menor)
      </li>
      <li class="my-1 mb-1">Modificar la explicación, la dificultad o el subtipo de la pregunta</li>
      <li class="my-1 mb-1">Añadir un mensaje de alarma de convocatoria en una pregunta</li>
    </ul>
  </div>
</template>

<style scoped></style>
