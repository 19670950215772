<script setup>
import { computed } from "vue";

const props = defineProps({
  isInteractive: {
    type: Boolean,
    default: false,
  },
  isFlex: {
    type: Boolean,
    default: false,
  },
});

const isInteractiveClass = computed(() => {
  return props.isInteractive ? "cursor-pointer hover:bg-secondary-100" : "";
});

const isFlexClass = computed(() => {
  return props.isFlex ? "flex flex-row" : "";
});

const extraClass = computed(() => {
  return isInteractiveClass.value + " " + isFlexClass.value;
});
</script>

<template>
  <div class="z-10 mx-auto mb-3 rounded-xl border border-secondary-100 bg-white p-3 drop-shadow-md" :class="extraClass">
    <slot></slot>
  </div>
</template>

<style scoped></style>
