<script setup>
import { onMounted, ref, watch } from "vue";
import {
  CategoryScale,
  Chart,
  Legend,
  LinearScale,
  LineController,
  LineElement,
  PointElement,
  Tooltip,
} from "chart.js";

Chart.register(LineElement, PointElement, LineController, CategoryScale, LinearScale, Tooltip, Legend);

const props = defineProps({
  correctAnswers: {
    type: Array,
    required: true,
  },
  incorrectAnswers: {
    type: Array,
    required: true,
  },
  blankAnswers: {
    type: Array,
    required: true,
  },
  isPercentage: {
    type: Boolean,
    required: true,
  },
  showDayInDateLabel: {
    type: Boolean,
    default: true,
  },
});

const lineChart = ref(null);
let chartInstance = null;

const renderChart = () => {
  if (chartInstance) {
    chartInstance.destroy();
  }

  chartInstance = new Chart(lineChart.value, {
    type: "line",
    data: {
      datasets: [
        {
          label: "Correctas",
          data: convertValuesToChart(props.correctAnswers),
          borderColor: "#C4D600",
          fill: false,
          tension: 0.4,
          pointRadius: 0,
        },
        {
          label: "Incorrectas",
          data: convertValuesToChart(props.incorrectAnswers),
          borderColor: "#EB4949",
          fill: false,
          tension: 0.4,
          pointRadius: 0,
        },
        {
          label: "En blanco",
          data: convertValuesToChart(props.blankAnswers),
          borderColor: "#97d5e8",
          fill: false,
          tension: 0.4,
          pointRadius: 0,
        },
      ],
    },
    options: {
      responsive: true,
      layout: {
        padding: 50,
      },
      interaction: {
        intersect: false,
      },
      scales: {
        y: {
          suggestedMin: props.isPercentage ? 0 : undefined,
          suggestedMax: props.isPercentage ? 100 : undefined,
          ticks: {
            stepSize: props.isPercentage ? 10 : undefined,
            backdropPadding: 100,
            callback: function (value) {
              return props.isPercentage ? value + "%" : value;
            },
          },
        },
      },
      plugins: {
        legend: {
          position: "bottom",
          labels: {
            generateLabels: function (chart) {
              const labels = Chart.defaults.plugins.legend.labels.generateLabels(chart);

              labels.forEach((label) => {
                label.lineWidth = 2;
                label.pointStyle = "circle";
              });

              return labels;
            },
            usePointStyle: true,
          },
        },
      },
    },
  });
};

const convertValuesToChart = (values) => {
  return values.map((v) => {
    if (!props.showDayInDateLabel) {
      return {
        x: v.date.slice(0, 7),
        y: v.value,
      };
    } else {
      return {
        x: v.date,
        y: v.value,
      };
    }
  });
};

onMounted(() => {
  renderChart();
});

watch(
  () => [props.correctAnswers, props.incorrectAnswers, props.blankAnswers],
  () => {
    renderChart();
  },
  { deep: true }
);
</script>
<template>
  <canvas ref="lineChart"></canvas>
</template>
