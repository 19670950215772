import axios from "axios";

export const baseDomain = import.meta.env.VITE_BACKEND_URL;

const baseCampusURL = `${baseDomain}/api-campus`;
const baseContentURL = `${baseDomain}/api-contenidos`;
const baseEditionURL = `${baseDomain}/api-edicion`;
const basePaymentsURL = `${baseDomain}/api-payments`;
const baseQuestionImporterURL = `${baseDomain}/question-importer`;
const baseAccountURL = `${baseDomain}/cuenta`;

axios.defaults.xsrfHeaderName = "X-CSRFToken";
axios.defaults.xsrfCookieName = "csrftoken";
axios.defaults.withCredentials = true;
axios.defaults.withXSRFToken = true;

export const authCampusAxios = axios.create({
  baseURL: baseCampusURL,
});
export const authContentAxios = axios.create({
  baseURL: baseContentURL,
});

export const authEditionAxios = axios.create({
  baseURL: baseEditionURL,
});

export const authPaymentAxios = axios.create({
  baseURL: basePaymentsURL,
});

export const authAccountAxios = axios.create({
  baseURL: baseAccountURL,
});

export const authQuestionImporterAxios = axios.create({
  baseURL: baseQuestionImporterURL,
});
