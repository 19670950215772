<script setup>
import FromRightTransition from "@/components/transitions/FromRightTransition.vue";
import { useWindowScroll, watchThrottled } from "@vueuse/core";
import { ref } from "vue";

const { y } = useWindowScroll();
const isScrolled = ref(false);

// avoid to change value too often to avoid bug with transitions
// since scroll events can be very fast
watchThrottled(
  y,
  (yScroll) => {
    isScrolled.value = yScroll > 0;
  },
  { throttle: 500 }
);

function goToTop() {
  window.scrollTo({ top: 0, left: window.scrollX, behavior: "smooth" });
}
</script>

<template>
  <from-right-transition>
    <div
      v-if="isScrolled"
      class="fixed right-10 z-50 cursor-pointer rounded-full bg-primary-100 p-2 drop-shadow-md hover:bg-primary-300 xl:bg-white xl:hover:bg-primary-200"
      @click="goToTop"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        fill="none"
        viewBox="0 0 24 24"
        stroke-width="1.5"
        stroke="currentColor"
        class="h-6 w-6"
      >
        <path stroke-linecap="round" stroke-linejoin="round" d="m4.5 15.75 7.5-7.5 7.5 7.5" />
      </svg>
    </div>
  </from-right-transition>
</template>

<style scoped></style>
