import {
  authAccountAxios,
  authCampusAxios,
  authContentAxios,
  authEditionAxios,
  authPaymentAxios,
  authQuestionImporterAxios,
} from "./repository";

export default {
  me() {
    return authCampusAxios.get("/me/");
  },
  logout() {
    return authAccountAxios.post("/cierre-sesion/");
  },
  async oppositionList(params) {
    return authEditionAxios.get("/oposiciones/", {
      params,
    });
  },
  async contentLevelList(params) {
    return authEditionAxios.get("/nivel-contenidos/", {
      params,
    });
  },
  async lawList(params) {
    return authEditionAxios.get("/leyes/", {
      params,
    });
  },
  oppositionDetail(id) {
    return authEditionAxios.get(`/oposiciones/${id}/`, {});
  },
  async examList(params) {
    return authEditionAxios.get("/examenes/", {
      params,
    });
  },
  examDetail(id) {
    return function () {
      return authEditionAxios.get(`/examenes/${id}/`, {});
    };
  },
  updateExamDetail(id) {
    return function (data) {
      return authEditionAxios.put(`/examenes/${id}/`, data);
    };
  },
  createExamDetail() {
    return function (data) {
      return authEditionAxios.post(`/examenes/`, data);
    };
  },
  syncQuestions() {
    return function (data) {
      return authEditionAxios.post(`preguntas/sync/`, data);
    };
  },
  async publishExam(examId) {
    return await authEditionAxios.post(`examenes/${examId}/publish/`);
  },
  async publishToNewsOppositionsExam(data) {
    return await authEditionAxios.post(`examenes/${data.id}/publish-to-new-oppositions/`, data);
  },
  async republishExam(examId) {
    return await authEditionAxios.post(`examenes/${examId}/republish/`);
  },
  async publishExamWithMinorChanges(examId) {
    return await authEditionAxios.post(`examenes/${examId}/republish-with-minor-changes/`);
  },
  async depublishExam(examId) {
    return await authEditionAxios.post(`examenes/${examId}/depublish/`);
  },
  async cloneExam(data) {
    return await authEditionAxios.post(`examenes/${data.id}/clone/`, data);
  },
  async removeExam(examId) {
    return await authEditionAxios.post(`examenes/${examId}/remove/`, examId);
  },
  async questionList(params) {
    return authEditionAxios.get("/preguntas/", {
      params: params,
      paramsSerializer: {
        indexes: null, // by default: false
      },
    });
  },
  async questionWithAnswersList(params) {
    return await authEditionAxios.get("/preguntas/questions-with-answers/", {
      params: params,
      paramsSerializer: {
        indexes: null, // by default: false
      },
    });
  },
  questionDetail(id) {
    return function () {
      return authEditionAxios.get(`/preguntas/${id}/`, {});
    };
  },
  createQuestion() {
    return function (data) {
      return authEditionAxios.post(`/preguntas/`, data);
    };
  },
  updateQuestionDetail(id) {
    return function (data) {
      return authEditionAxios.put(`/preguntas/${id}/`, data);
    };
  },
  async saveQuestionImage(id, file) {
    return await authEditionAxios.post(
      `/preguntas/${id}/save-image/`,
      { file },
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
  },
  async deleteQuestionImage(id) {
    return await authEditionAxios.delete(`/preguntas/${id}/delete-image/`);
  },
  async markEditionContentLevelAsNoReviewed(contentLevels) {
    return await authEditionAxios.post("/nivel-contenidos/mark-as-not-reviewed/", { contentLevels: contentLevels });
  },
  async markEditionExamQuestionsAsNoReviewed(examId) {
    return await authEditionAxios.post("/preguntas/mark-as-not-reviewed/", { examId });
  },
  async markEditionQuestionsAsNoReviewed(questionId) {
    const url = `/preguntas/${questionId}/mark-question-as-not-reviewed/`;
    return await authEditionAxios.post(url);
  },
  async publicExaminationList(params) {
    return authCampusAxios.get("/oposiciones/", {
      params: params,
    });
  },
  publicExaminationDetail() {
    return function (data) {
      const slug = data.slug;
      return authCampusAxios.get(`/oposiciones/${slug}/`, data);
    };
  },

  async publicExaminationStatistics({ slug, timeRange, userId }) {
    let params = { time_range: timeRange };
    if (userId) {
      params.user_id = userId;
    }
    return authCampusAxios.get(`/estadisticas/${slug}/`, {
      params: params,
    });
  },
  async notificationList(params) {
    return await authContentAxios.get("/notificaciones/", {
      params,
    });
  },
  async notificationDetail(id) {
    return await authContentAxios.get(`/notificaciones/${id}/`, {});
  },
  async markNotificationAsSeen(notificationId) {
    return await authContentAxios.put(`/notificaciones/${notificationId}/notificacion-leida/`);
  },
  async getPendingNotifications() {
    return await authContentAxios.get(`/notificaciones/pendientes/`);
  },

  async attemptList(params) {
    return authContentAxios.get("/intentos/", {
      params: params,
    });
  },
  attemptInProgress() {
    return function (params) {
      const slug = params.slug;
      return authContentAxios.get(`/intentos/en-progreso/${slug}/`, {});
    };
  },
  attemptDetail(uuid) {
    return function () {
      return authContentAxios.get(`/intentos/${uuid}/`, {});
    };
  },
  newAttemptInfo() {
    return function (data) {
      return authContentAxios.post(`/intentos/nuevo-info/`, data);
    };
  },
  newAttempt() {
    return function (data) {
      return authContentAxios.post(`/intentos/`, data);
    };
  },
  updateAttempt(uuid) {
    return function (data) {
      return authContentAxios.put(`/intentos/${uuid}/`, data);
    };
  },
  duplicateAttempt(uuid) {
    return function () {
      return authContentAxios.post(`/intentos/${uuid}/duplicar-intento/`);
    };
  },
  markUnmarkExamAsFinished(uuid) {
    return function (data) {
      return authContentAxios.post(`/intentos/${uuid}/marcar-desmarcar-examen-como-finalizado/`, data);
    };
  },
  reportQuestionError() {
    return function (data) {
      return authContentAxios.post(`/intentos/error-pregunta/`, data);
    };
  },
  async contentContentLevelList(params) {
    return authContentAxios.get("/niveles-contenido/", {
      params,
    });
  },
  async contentLawList(params) {
    return authContentAxios.get("/leyes/", {
      params,
    });
  },
  async contentExamList(params) {
    return authContentAxios.get("/examenes/", {
      params,
    });
  },
  examStatistics() {
    return function (params) {
      const slug = params.slug;
      return authContentAxios.get(`/examenes/estadisticas/${slug}/`, {});
    };
  },
  // PAYMENTS
  async createSubscriptionLink(productId) {
    let response = await authPaymentAxios.get(`create-subscription/${productId}/`);
    return response.data.url;
  },
  async manageSubscriptionLink() {
    let response = await authPaymentAxios.get("manage-subscription/");
    return response.data.url;
  },
  async checkImportQuestions(file) {
    return authQuestionImporterAxios.request({
      url: "/importar-preguntas-con-documento/prueba/",
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: { file },
    });
  },
  async checkImportExam(file, exam_type) {
    return authQuestionImporterAxios.request({
      url: "/importar-examen-con-documento/prueba/",
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: { file, exam_type },
    });
  },
  async importQuestions(file, oppositions, subtype) {
    return authQuestionImporterAxios.request({
      url: "/importar-preguntas-con-documento/",
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: { file, oppositions, subtype: subtype },
    });
  },
  async importExam(file, oppositions, category, exam_type) {
    return authQuestionImporterAxios.request({
      url: "/importar-examen-con-documento/",
      method: "POST",
      headers: { "Content-Type": "multipart/form-data" },
      data: { file, oppositions, category: category, exam_type },
    });
  },
};
