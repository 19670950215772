<script setup>
import BaseCard from "@/components/BaseCard.vue";

import PriceSwitch from "@/components/payments/PriceSwitch.vue";
import { computed, ref } from "vue";
import MainRepository from "@/repositories/MainRepository.js";
import { useToast } from "@/components/composables/notifications.js";
import FadeTransition from "@/components/transitions/FadeTransition.vue";

const props = defineProps({
  publicExaminations: {
    type: Array,
    required: true,
  },
  selectedPublicExamination: {
    type: [String, null],
    default: null,
  },
});

const { sendWarnNotification, sendServerError } = useToast();

const title = computed(() => {
  if (props.selectedPublicExamination != null) {
    return "Elige la duración de la subscripción";
  }
  return "Elige la oposición a la que quieras subscribirte";
});

const goToPayment = async (slug) => {
  const price = findPriceOfPublicExamination(slug);
  if (!price) {
    sendWarnNotification("No se ha podido realizar la acción", "No existe esta posibilidad de pago.");
    return;
  }
  try {
    window.location = await MainRepository.createSubscriptionLink(price.priceId);
  } catch (e) {
    let error = e.response.data;
    console.log(error.detail);
    sendServerError(null, error.code);
  }
};

const PRICE_MONTHLY = "monthly";
const PRICE_EVERY6MONTHS = "every_6_month";
const PRICE_YEARLY = "yearly";
const PRICE_MODES = [
  { name: "1 mes", option: PRICE_MONTHLY },
  { name: "6 meses", option: PRICE_EVERY6MONTHS },
  { name: "1 año", option: PRICE_YEARLY },
];

const priceMode = ref(PRICE_MONTHLY);

const priceChange = (newPriceMode) => {
  priceMode.value = newPriceMode;
};

const displayPriceOfPublicExamination = (slug) => {
  const price = findPriceOfPublicExamination(slug);
  if (!price) {
    return "No existe esta posibilidad de pago.";
  }
  return price.fancyPrice;
};

const findPriceOfPublicExamination = (slug) => {
  const publicExamination = props.publicExaminations.find((element) => element.slug === slug);
  return publicExamination.prices.find((p) => p.paymentMode === priceMode.value);
};
</script>

<template>
  <div class="flex flex-col items-center">
    <h2 class="mx-5 my-16 text-4xl font-semibold text-secondary-800 sm:mx-0">{{ title }}</h2>
    <base-card class="w-full p-8">
      <price-switch class="mb-7" :options="PRICE_MODES" @change="priceChange"></price-switch>
      <div class="grid cursor-pointer grid-cols-1 gap-4 sm:grid-cols-2 md:grid-cols-6">
        <div
          v-for="(publicExamination, index) in publicExaminations"
          :key="publicExamination.slug"
          class="h-full cursor-pointer rounded rounded-lg bg-background px-6 py-4 align-middle hover:bg-background-hover"
          :class="[
            index > 2 ? ' md:col-start-2' : '',
            selectedPublicExamination == null ? 'md:col-span-2' : 'sm:col-span-2 md:col-span-6',
          ]"
          @click="goToPayment(publicExamination.slug)"
          v-show="selectedPublicExamination == null || selectedPublicExamination === publicExamination.slug"
        >
          <div class="flex h-full flex-col items-center justify-center">
            <div class="mb-1 flex text-xl font-semibold text-secondary-800">
              <span>{{ publicExamination.shortName }}</span>
            </div>
            <div class="mb-1 flex text-base font-light text-secondary-800">
              <span>{{ publicExamination.name }}</span>
            </div>
            <fade-transition>
              <span class="text-base font-semibold text-secondary-800" :key="priceMode">
                {{ displayPriceOfPublicExamination(publicExamination.slug) }}
              </span>
            </fade-transition>
          </div>
        </div>
      </div>
    </base-card>
  </div>
</template>

<style lang="scss" scoped></style>
