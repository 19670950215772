<script setup>
import { Switch } from "@headlessui/vue";

const props = defineProps({
  modelValue: {
    type: [Boolean, String],
    default: null,
  },
  id: {
    type: String,
    default: "id-range",
  },
  name: {
    type: String,
    default: "range",
  },
  disabled: {
    type: Boolean,
    default: false,
  },
});
function click() {
  emit("change", !props.modelValue);
}
const emit = defineEmits(["change"]);
</script>

<template>
  <div>
    <Switch
      :value="modelValue ? 'true' : 'false'"
      @click="click"
      :id="id"
      :name="name"
      :class="modelValue ? 'bg-secondary-600' : 'bg-secondary-400'"
      class="relative inline-flex h-[21px] w-[37px] shrink-0 cursor-pointer rounded-full border-2 border-transparent transition-colors duration-200 ease-in-out focus:outline-none focus-visible:ring-2 focus-visible:ring-white focus-visible:ring-opacity-75"
    >
      <span class="sr-only"><slot></slot></span>
      <span
        aria-hidden="true"
        :class="modelValue ? 'translate-x-4' : 'translate-x-0'"
        class="pointer-events-none inline-block h-[17px] w-[17px] transform rounded-full bg-white shadow-lg ring-0 transition duration-200 ease-in-out"
      />
    </Switch>
  </div>
</template>
