import { ref } from "vue";
import * as Sentry from "@sentry/browser";

export function useWS(webserviceCall, showLoadingAtStart = false) {
  const isLoading = ref(showLoadingAtStart);
  const error = ref("");
  const erroMsg = ref(null);
  const record = ref(null);

  const callWs = async function (params) {
    error.value = "";

    try {
      isLoading.value = true;
      const resp = await webserviceCall(params);
      record.value = resp.data;
      isLoading.value = false;
    } catch (e) {
      if (!e.response) {
        Sentry.withScope(function (scope) {
          scope.setTag("wopo-error-wscall", webserviceCall);
          Sentry.captureException(e);
        });
      } else {
        if (!e.response.hasOwnProperty("data") || !e.response.hasOwnProperty("status")) {
          Sentry.withScope(function (scope) {
            scope.setTag("wopo-error-wscall", webserviceCall);
            Sentry.captureException(e);
          });
        }
        error.value = e.response.status;
        erroMsg.value = e.response.data;
      }
      // console.log(e);
      isLoading.value = false;
      throw e;
    }
  };

  return { callWs, record, isLoading, error, erroMsg };
}
