import { createApp, ref } from "vue";
import "./style.css";
import App from "./App.vue";
import { buildRouter } from "@/router/router.js";
import Notifications from "@kyvg/vue3-notification";
import velocity from "velocity-animate";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";

const user = ref(null);

const app = createApp(App);
app.provide("user", user);

const router = buildRouter(user);
const pinia = createPinia();

if (process.env.NODE_ENV === "production") {
  let config = {
    app,
    dsn: "https://ad303f77284a3a3f048c2f15920951fd@o395058.ingest.sentry.io/4506376575320064",
    integrations: [],
  };
  if (!!import.meta.env.VITE_IS_QA) {
    config.environment = "qa";
  }
  Sentry.init(config);
}

app.use(router);
app.use(pinia);

app.use(Notifications, { velocity });

app.mount("#app");
